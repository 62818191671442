<template>
	<section :class="classes">
		<h2 class="fb__title--hidden">선택박스</h2>
		<button type="button" class="fb__select-box__btn" :disabled="disabled" @click.self="selectboxOpen()">
			<slot></slot>
		</button>
		<div :class="isOpen ? 'fb__select-box fb__select-box--open' : 'fb__select-box'">
			<button type="button" class="fb__select-box__down" @click.self="selectboxOpen()"></button>
			<div class="fb__select-box__options">
				<slot name="options"></slot>
			</div>
		</div>
		<div class="fb__select-box__mask" @click.self="isBackgroundClose ? selectboxOpen() : null"></div>
	</section>
	
</template>
<script>
	import eventBus from "../mixins/event-bus";
    export default {
		name : "select-box-component",
		mixins: [eventBus],
		props: {
			classes: {
				type: String,
				default: null
			},

			isBackgroundClose: {
				type: Boolean,
				default: true
			},

			disabled: {
				type: Boolean,
				default: false
			}
		},

		data() {
			return {
				isOpen: false
			}
		},

		watch: {
			isOpen: {
				handler(newValue, oldValue) {
					if(newValue) {
						document.body.style.width = document.body.scrollWidth + 'px';
						document.body.classList.add("scroll-lock");
					}
					else {
						document.body.style.width = null;
						document.body.classList.remove("scroll-lock");

						this.$nextTick(() => {
							this.$emit("loaded", true);
						})
					}
				},
			}
		},

		created() {
			this.$parent.$on("select-open", (isOpen) => {
				this.isOpen = isOpen;
				window.VueEventBus.$emit("dockbar-visible", !this.isOpen);
			});
		},

		methods: {
			selectboxOpen() {
				this.isOpen = !this.isOpen;
				window.VueEventBus.$emit("dockbar-visible", !this.isOpen);
			}
		}
    }
</script>
<style scoped lang="scss">
	.fb {
		&__select-box {
			display: block;
			position: fixed;
			z-index: 6;
			right: 0;
			bottom: -100%;
			left: 0;
			width: 100%;
			height: auto;
			// max-height: 38.76923%;
			max-height: 50%;
			background: #fff;
			border-radius: 4vw 4vw 0 0;
			transition: bottom ease .5s;

			&--open {
				bottom: 0;

				& + .fb__select-box__mask {
					display: block;
				}
			}

			&__btn {
				position: relative;
				height: 7.33333vw;
				padding: 0 9.59999vw 0 3.33333vw;
				border: 0.26667vw solid #d2d2d2;
				color: #000;
				font-size: 4vw;
				line-height: 7.33333vw;
				box-sizing: border-box;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				&::after {
					display: block;
					position: absolute;
					top: 0;
					right: 2.66666vw;
					width: 3.5vw;
					height: 100%;
					background: url(/mobile/assets/images/common/btn-select.png) no-repeat center center;
					background-size: 2.93333vw 1.86667vw;
					content: "";
				}
			}

			&__mask {
				display: none;
				position: fixed;
				z-index: 5;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background:rgba(0, 0, 0, 0.5);
			}

			&__options {
				padding: 10.66666vw 0 14.66666vw 0;
			}

			&__down {
				position: absolute;
				top: -2.4vw;
				left: 50%;
				width: 31.466vw;
				height: 3.4vw;
				margin-left: -15.733vw;
				background: #fff url(/mobile/assets/images/common/ico_select-custom_arrow.png) no-repeat 50% 50% / 3.733vw 1.2vw;
				border-radius: 4vw 4vw 0 0;
			}
		}
	}
</style>