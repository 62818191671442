<template>
    <section class="fb__toast__mask" @click.self="isBackgroundClose ? close() : null" role="dialog" :class="isCategory ? 'categoryDown' : ''" :style="!isMaskBackground ? 'background: transparent;' : ''">
		<div :class="`fb__toast__wrapper ${classes || ''}`" :style="`${width ? 'width: ' + width + ';' : ''}${height ? 'height: ' + height + ';' : ''}${background ? 'background: ' + background : ''}`">
			<button class="fb__toast__close" v-if="isCloseButton" @click="close">닫기버튼</button>
			<slot></slot>
		</div>
	</section>
</template>
<script>
    export default {
        name : "toast-component",

		props: {
			classes: {
				type: String,
				default: null
			},

			width: {
				type: String | Number,
				default: null
			},

			height: {
				type: String | Number,
				default: null
			},

			background: {
				type: String,
				default: null
			},

			isMaskBackground: {
				type: Boolean,
				default: true
			},

			isBackgroundClose: {
				type: Boolean,
				default: true
			},

			isCloseButton: {
				type: Boolean,
				default: true
			},

			isCategory: {
				type: Boolean,
				default: false
			},
		},
		
		mounted() {
			document.body.style.width = document.body.scrollWidth + 'px';
			document.body.classList.add("scroll-lock");

			this.$nextTick(() => {
				this.$emit("loaded",true);

				document.querySelector(".fb__toast__wrapper").classList.add("show");
			})
		},

		beforeDestroy() {
			document.body.style.width = null;
			document.body.classList.remove("scroll-lock");
		},
		
        methods: { 
			close(e) {
				document.querySelector(".fb__toast__wrapper").classList.remove("show");
				this.$nextTick(() => {
					setTimeout(() => {
						this.$emit("close-modal", {
							e
						});
					}, 300);
				});
			},
		},
    }
</script>
<style scoped lang="scss">
	.fb {
		&__toast {
			&__mask {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				align-items: center;
				position: fixed;
				z-index: 10;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background: rgba(0, 0, 0, .5);

				&.categoryDown {
					top: auto;
					bottom: 0;
					height: calc(100% - 14.5vw);
					background: rgba(0, 0, 0, 0.5);
				}
			} 

			&__wrapper {
				position: absolute;
				left: 0;
				bottom: 0;
				width:100%;
				margin: auto;
				background-color: #fff;
				border-radius: 30px 30px 0 0;
				box-shadow: -3px 0 8px rgba(0,0,0,0.08);
				transform:translateY(100%);
				transition:all 0.3s linear;
			}

			&__close {
				position: absolute;
				top: 4vw;
				left: 50%;		
				z-index: 5;
				width: 8.533333vw;
				height: 0.8vw;
				background-color:#EBEBEB;
				transform:translateX(-50%);
				border-radius:10px;
				cursor: pointer;
				text-indent:-9999em;
				overflow:hidden;
			}
		}
	}
</style>