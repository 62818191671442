<template>
    <section>
		<div class="full-width py-3 text-center" v-if="loading">
			<slot name="loading"></slot>
		</div>
		<div v-else-if="pagination.current < pagination.max">
			<button class="btn__more" @click="movePage($event)" :disabled="disabled">
				<span>더보기</span>
			</button>
		</div>
	</section>
</template>
<script>
    export default {
        name : "page-more-component",

		props: {
            pagination: {
                type: Object,
				default: () => {
					return {
						id: null,
						current: 1,
						max: 10
					}
				},
			},

			loading: {
				type: Boolean,
				default: false
			},

			disabled: {
				type: Boolean,
				default: false
			}
		},
		
		watch: {
			pagination: {
				handler(newValue, oldValue) {
					if(newValue.current > newValue.max) newValue.current = newValue.max;
				},
				deep: true,
			},
		},

        data() {
            return {
            	items: null
            }
		},
		
        methods: {
            movePage(e) {
				this.$emit('move-page', {
					e: e,
					id: this.pagination.id,
					page: this.pagination.current + 1
				});
			}
        }
    }
</script>
<style scoped lang="scss">
</style>