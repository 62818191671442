/*
	file mixin
*/

const files = {
	methods: {
		filterNotAllowedFiles(id, element, files, errorFiles) {
			const allowedFiles = new DataTransfer();
			const targets = Array.from(files);

			targets.filter(v => !errorFiles.includes(v)).forEach((v, i) => {
				allowedFiles.items.add(v);
			});

			element.files = allowedFiles.files;
			this.completedFilesImport(id, element, files, errorFiles);
		},

		async readAsDataURL(file) {
			return new Promise((resolve, reject) => {
			  try {
				const reader = new FileReader();
				
				reader.readAsDataURL(file);
				reader.onload = function(e) {
				  resolve(e.target.result);
				}
			  }
			  catch(ex) {
				reject(ex);
			  }
			});
		},

		async importFiles(id, e, files, fileType, maximumFileSize, maximumCount) {
			const errorFiles = [];
			let successFileCount = 0;
			let file = null;
			let fileName = null;
			let size = 0;

			if (!maximumFileSize) maximumFileSize = 1024;

			for (let i = 0, maxCnt = files.length; i < maxCnt; i++) {
				file = files.item(i);
				fileName = file.name;
				size = file.size / 1024 / 1024;;

				if (maximumCount && successFileCount >= maximumCount) {
					this.fileCountError(id, e.target);

					break;
				}

				if (fileType) {
					if(null == file.type.match(fileType)) {
						errorFiles.push(file);
						this.fileFormatError(id, e.target, file);
	
						continue;
					}
				}

				if (fileName.substring(fileName.indexOf(".") + 1, fileName.lastIndexOf(".")).length > 1) {
					this.fileNameError(e.target, file);
					
					continue;
				}

				if (maximumFileSize < size) {
					errorFiles.push(file);
					this.fileSizeError(id, e.target, file);
				}
				else {
					successFileCount++;
					this.completedFileImport(id, e.target, file);
				}
			}

			this.filterNotAllowedFiles(id, e.target, files, errorFiles);
		},

		fileFormatError(id, element, file) {
			const alert = this.alert;

			if (alert) {
				alert.message = "파일은 jpg, jpeg, png만 등록 가능합니다."
				alert.isOpen = true;
			}
			
			console.error("file foramt error... ", element, file);
		},

		fileNameError(element, file) {
			const alert = this.alert;

			if (alert) {
				alert.message = `허용된 확장자가 아닙니다.<br> 파일명을 다시 확인해주세요.`;
				alert.isOpen = true;
				element.value = "";
			}

			console.error("file name error... ", element, file);
		},

		fileSizeError(id, element, file) {
			const alert = this.alert;
			if (alert) {
				alert.message = "사진은 최대 30MB까지 등록 가능합니다."
				alert.isOpen = true;
			}
			console.error("file size error... ", element, file);
		},

		fileCountError(id, element) {
			const alert = this.alert;

			if (alert) {
				alert.message = "최대 3개의 파일만 업로드 가능합니다.";
				alert.isOpen = true;
			}

			console.error("file count error... ", element);
		},

		completedFileImport(id, element, file) {
			console.log("completed file import... ", element, file);
		},

		completedFilesImport(id, element, files, errorFiles) {
			console.log("completed files import... ", element, files, errorFiles);
		},

		maximumAlert(e, files) {
			const alert = this.alert;
			if (alert) {
				alert.message = "최대 3개의 파일만 업로드 가능합니다."
				alert.isOpen = true;
			}
		}
	}
}

export default files;