<template>
    <section class="fb__modal__mask" @click.self="isBackgroundClose ? close() : null" role="dialog" :style="!isMaskBackground ? 'background: transparent;' : ''">
		<div :class="`fb__modal__wrapper ${classes || ''}`" :style="`${width ? 'width: ' + width + ';' : ''}${height ? 'height: ' + height + ';' : ''}${background ? 'background: ' + background : ''}`">
			<header class="fb__modal__top">
				<h3 :class="`fb__modal__title ${getLanguageClass(title)}`" v-html="title"></h3>
				<button class="fb__modal__close" v-if="isCloseButton" @click="close">닫기버튼</button>
			</header>
			<slot></slot>
		</div>
	</section>
</template>
<script>
    export default {
        name : "simple-modal-component",

		props: {
			classes: {
				type: String,
				default: null
			},

			width: {
				type: String | Number,
				default: null
			},

			height: {
				type: String | Number,
				default: null
			},

			background: {
				type: String,
				default: null
			},

			isMaskBackground: {
				type: Boolean,
				default: true
			},

			isBackgroundClose: {
				type: Boolean,
				default: true
			},

			isCloseButton: {
				type: Boolean,
				default: true
			},

			title: {
				type: String,
				default: "레이어팝업"
			}
		},
		
		mounted() {
			document.body.style.width = document.body.scrollWidth + 'px';
			document.body.classList.add("scroll-lock");

			this.$nextTick(() => {
				this.$emit("loaded",true);
			})
		},

		beforeDestroy() {
			document.body.style.width = null;
			document.body.classList.remove("scroll-lock");
		},
		
        methods: {
			close(e) {
				this.$emit("close-modal", {
					e
				});
			},

			getLanguageClass(text) {
                const langExp = /[a-zA-Z]/;
                if (langExp.test(text)) return 'fb__font__e';
                else return '';
            }
		},
    }
</script>
<style scoped lang="scss">
	$max-width: 100%;
	$max-height: 100%;
	$min-width: 100px;
	$min-height: 100px;

	.fb {
		&__modal {
			&__mask {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				align-items: center;
				position: fixed;
				z-index: 10;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}

			&__wrapper {
				overflow-y: auto;
				position: absolute;
				top: 50%;
				left: 50%;
				padding: 4vw;
				background: #fff;
				transform: translate(-50%, -50%);
				box-sizing: border-box;
				box-shadow: 0.66667vw 1.066667px 6.66667vw rgba(0,0,0, 0.3);
			}

			&__top { 
				display: block;
				padding-bottom: 4vw;
				border-bottom: 0.26667vw solid #e5e5e5;
			}

			&__title {
				color: #000;
				font-size: 4.533333vw;
				line-height: 4vw;
			}

			&__close {
				position: absolute;
				top: 4vw;
				right: 4vw;		
				z-index: 5;
				width: 4vw;
				height: 4vw;
				background: url(/mobile/assets/images/common/btn-modal-close-small.png) no-repeat 0 0;
				background-size: 4vw 4vw;
				font-size: 0;
				cursor: pointer;
			}
		}
	}
</style>