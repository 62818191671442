const mixin = {
	data() {
		return {
			// 입력 폼 페이지에서 사용
			isPassBeforeunload: false,
		}
	},

	methods: {
		// input value maxlength
		eventElementValueMaxLengthBlock(e, length) {
			if(length == e.target.value.length) e.preventDefault();
		},

		// input value maxlength
		getCutStringLength(e, value, length) {
			if(value && length) {
				if(length < value.length) return value.substring(0, length);
				else return value;
			} else {
				return value;
			}
		},

		//
		getParameters() {
			return new URLSearchParams(window.location.search);
		},

		// get parameters query string
		getQueryString() {
			return new URLSearchParams(window.location.search);
		},

		getCurrentTime(format) {
			return moment().format(format || "YYYY-MM-DD");
		},

		// object
		getExistProperties(value) {
			const newObject = {};

			for(let [k, v] of Object.entries(value)) {
				if(v) newObject[k] = v;
			}

			return newObject;
		},

		/*
			target: Object = {}

			items: Array = [{
				key: ["id"],
				errorKey: ["idError"],
				regex: null,
				ref: "id",
				refIndex: 0,
				message: "아이디를 입력해 주세요.",
			}, {
				key: ["id"],
				errorKey: ["idError"],
				regex: null,
				ref: "id",
				refIndex: 1,
				message: "아이디를 입력해 주세요.",
			}]

		*/
		verification(targets, items) {
			let isVerification = true;

			const loop = (target) => {
				for(let i = 0, maxCnt = items.length; i < maxCnt; i++) {
					const item = items[i];
					let ref = null;
					let isPass = true;
	
					for(let j = 0, maxCnt = item.key.length; j < maxCnt; j++) {
						let value = target[item.key[j]];
						let regex = item.regex instanceof Array ? item.regex[j] : item.regex;

						if(isPass) {
							isPass = (value instanceof Array && value.length) 
									|| (
										!(value instanceof Array)
										&& ((
											!regex
											&& value
											&& ((
													!Number.isInteger(value)
													&& value.trim()
												)
												|| Number.isInteger(value)
											)
										)
										|| (
											regex
											&& regex.test(value)
										))
									)
						}
					}
	
					if(isVerification) isVerification = isPass;
					if(item.ref) {
						ref = item.refIndex ? this.$refs[item.ref][item.refIndex] : this.$refs[item.ref];

						if(ref) {
							if(isPass) ref.innerHTML = "";
							else ref.innerHTML = item.message;
						}
					}
					if(item.hasOwnProperty("errorKey")) {
						if(isPass) target[item.errorKey] = "";
						else target[item.errorKey] = item.message;
					}
				}
			}

			if(!items) return false;
			if(targets instanceof Array) {
				for(let i = 0, maxCnt = targets.length; i < maxCnt; i++) {
					let target = targets[i];

					loop(target);
				}
			}
			else {
				loop(targets);
			}

			return isVerification;
		},

		// date ...
		stringDateToDate(stringDate, format) {
			return moment(stringDate, "YYYYMMDDhhmmss").format(format || "YYYY.MM.DD")
		},
		
		timestampToDate(timestamp, format) {
			return moment(timestamp).format(format || "YYYY.MM.DD");
		},

		toDate(date, format) {
			return moment(date).format(format || "YYYY.MM.DD");
		},

		// page move
		moveURL(url, isNewTab) {
			if(isNewTab) window.open(url, "_blank");   
			else window.location.href = url;
		},

		// 
		getLanguageClass(text) {
			const langExp = /[a-zA-Z]/;
			
			if (langExp.test(text)) return ' fb__font__e';
			else return '';
		},

		unescapeHtml(html) {
			if(!html) return "";

			const el = document.createElement("div");
			
			el.innerHTML = html;

			return el.innerText;
		},

		// events
		historyBack(count) {
			if(1 == window.history.length) {
				window.location.href = "/index";
			}
			else {
				window.history.back(count || -1);
			}
		},

		addEventBeforeunload() {
			this.isPassBeforeunload = true;
			window.addEventListener('beforeunload', this.beforeunload);
		},

		removeEventBeforeunload() {
			this.isPassBeforeunload = false;
			window.removeEventListener('beforeunload', this.beforeunload);
		},

		beforeunload(e) {
			if(!this.isPassBeforeunload) e.returnValue = '입력한 데이터는 저장되지 않습니다.';
		},

		/**
		 * element events
		 * 
		 * element attr disabled 수정
		 */
		disableEvent(e, value) {
			if(e && e.target) e.target.disabled = value;
		},

		/**
		 * element events - img tag
		 * 
		 * image load error 발생 시 해당 src 이미지로 변경
		 */
		imgLoadError(e, src) {
			e.target.src = src || "/pc/assets/images/common/noimg.gif";
		},

		/**
		 * calculation
		 * 
		 * (A - B) / A * 100 
		 * 1 - (A / B) 
		 */
		discountRate(costPrice, salePrice) {
			return Math.floor((costPrice - salePrice) / costPrice * 100);
		},

		/**
		 * asdasd<br> asdasdasd
		 * 
		 * result
		 *  asdasd asdasdasd 
		 */ 
		stringResult(result) {
			return result.replace(/(<br>|<br\/>|<br \/>)/g, '');
		},



		/**
		 * format
		 * 
		 * 금액 사이에 콤마 표시 (한화 기준)
		 */
		toPrice(value) {
			if("" == value) return "";

			const number = parseInt(value);
			
			return number ? number.toLocaleString("ko-KR") : 0;
		},

		/**
		 * format
		 * 
		 * 금액 사이에 콤마 표시 (한화 기준)
		 */
		numberToMoney(value) {
			const values = value.match(/([0-9]+|[^0-9]+)/g);
			const results = [];

			for(let i = 0, maxCnt = values.length; i < maxCnt; i++) {
				const value = values[i];
				const number = parseInt(value);

				results.push(number ? number.toLocaleString("ko-KR") : value);
			}

			return results.join("");
		},
		
		/**
		 * format
		 * 
		 * 핸드폰번호 분리 (하이픈 x)
		 * ex) 01011111111
		 */
		splitPhone(value) {
			if(!value) return "";

			value = value.toString();
			
			const splitValue = /([0-9]{3})([0-9]{4})([0-9]{4})/.exec(value);

			if(splitValue && splitValue.length) splitValue.splice(0, 1);

			return splitValue;
		},

		getUserAgent() {
			if (/(CasamiaAOSApp)/ig.test(window.navigator.userAgent)) return "AOS";
			else if (/(CasamiaIOSApp)/ig.test(window.navigator.userAgent)) return "IOS";
			else return "";
		},

		isApp() {
			if (/(CasamiaAOSApp|CasamiaIOSApp)/ig.test(window.navigator.userAgent)) return true;
			else return false;
		},

		getAppVersion() {
			const useragent = window.navigator.userAgent;
			let arr = null;

			if (this.getUserAgent() == "IOS" || this.getUserAgent() == "AOS") {
				arr = useragent.split("//");
				return arr[arr.length - 1];
			}
		},

		goAppStore() {
 		 	if (this.getUserAgent() === "AOS") {
				// 안드로이드 플레이스토어 이동
				window.JavascriptInterface.updateStore();
			} 
			else if (this.getUserAgent() === "IOS") {
				// IOS 앱스토어 이동
				window.webkit.messageHandlers.updateStore.postMessage("");
			}
		},

		goAppAR() {
			if (this.getUserAgent() === "AOS") window.JavascriptInterface.arBtnClick();
			else if (this.getUserAgent() === "IOS") window.webkit.messageHandlers.arBtnClick.postMessage("");
		},

		// XSS 공격 방지
		getXSSFiltering(text) {
			return window.filterXSS(text, { stripIgnoreTag : true });
		},
	},

	filters: {
		price(value) {
			const number = parseInt(value);
			
			return number ? parseInt(value).toLocaleString("ko-KR") : 0;
		},

		decimalRoundDown(value) {
			const float = parseFloat(value);
			
			return float ? Math.floor(float) : 0;
		},

		phone(value) {
			if(!value) return "";

			value = value.toString();
			return value.toString().replace(/([0-9]{3})([0-9]{4})([0-9]{4})/, "$1-$2-$3");
		},

		arrayReverse(array) {
			return array.slice().reverse();
		},

		masking(value, num) {
			if(!value) return "";
			value = value.toString();
			let html = "";
			for(let i=0, maxLength = value.slice(2,value.length).length ; i < maxLength; i++) {
				html += "*"
			};


			return value.replace(value.slice(2), html)
		}
	}
}

export default mixin;