<template>
    <header id="targetGoing" :class="isGnb ? 'fb__header isGnb' : 'fb__header'">
		<section class="header__topBanner" id="topBanner" v-if="$b2bSessionFlag">
        	<div class="header__topBanner__content">
            	<a href="/b2b/index" ><span class="blind">배너</span></a> 
        	</div>   
    	</section> 
		<div ref="fbHeader" id="fbHedaer" class="fb__header__inner 20220118">  
			<div class="fb__header__inner__event" ref="topEventBanners">  
				<div class="fbEvent_top" v-if="isTopEvent">

					<!--쓱데이 시작 전 띠배너-->   
					<template v-if="ssgDayState.comming == true"> 
						<a href="/event/goodsEventDetail?DP_DISPLAY_ID=7022&TYPE=3&SUB_TYPE=3" class="fb__header__inner__event__link comming">
							<img src='https://image.guud.com/mall/DESIGN/EVENT/SHOP/2021/10/20211018/main/m/m_top_event_banner.gif' alt='대한민국 쓱데이, 굳닷컴 사전예약 10.18 ~ 10.24'>
						</a>  
						<a href="javascript:;" class="fb__header__inner__event__close" @click="closeTodayModal($event, 'topEvent')">
							<img src="https://image.guud.com/mall/DESIGN/EVENT/SHOP/2021/10/20211018/main/m/m_top_event_close.png" alt="닫기"> 
						</a>        
					</template>
					<!--쓱데이 시작 후 띠배너 -->  
					<template v-else-if="ssgDayState.today == true "> 
						<a href="/ssgDay" class="fb__header__inner__event__link today">
							<img src='https://image.guud.com/mall/DESIGN/EVENT/SHOP/2021/10/20211021/main/m/m_top_event_banner.gif' alt='대한민국 쓱데이, 10.25 10:00 ~ 11:1 09:59'>
						</a>  
						<a href="javascript:;" class="fb__header__inner__event__close" @click="closeTodayModal($event, 'topEvent')">
							<img src="https://image.guud.com/mall/DESIGN/EVENT/SHOP/2021/10/20211018/main/m/m_top_event_close.png" alt="닫기">  
						</a>  
						
					</template>
					<!--쓱데이 종료 후 까사라는 신세계 띠배너 및 링크-->
					<template v-else>  
						<a href="/event/goodsEventDetail?DP_DISPLAY_ID=6083&TYPE=3&SUB_TYPE=3" class="fb__header__inner__event__link">
							<img src='https://image.guud.com/mall/DESIGN/EVENT/SHOP/2021/09/20210923/main/m/m_top_event_banner.gif' alt='casamia, casa라는 신세계'>
						</a>  
						<a href="javascript:;" class="fb__header__inner__event__close" @click="closeTodayModal($event, 'topEvent')">
							<img src="https://image.guud.com/mall/DESIGN/EVENT/SHOP/2021/09/20210923/main/m/m_top_event_close.png" alt="닫기"> 
						</a>  
						
					</template>  

				

				</div>   
			</div>	 
			<div class="fb__top" ref="fbTop"> 
				<!-- 햄버거 --> 
				<button class="fb__top__lnb" v-if="isLnb" @click="openModal('category')"> 
					<span class="blind">햄버거</span>
				</button>

				<!-- back 버튼 -->
				<button class="fb__top__back" v-if="isBack" @click.prevent="historyBackInit($event)">뒤로가기</button>

				<!-- 일반 타이틀 -->
				<template v-if="false === isCategory">
					<h1 class="fb__top__title" v-if="topTitle">
						<template v-if="'Casamia' == title || !title">
							<a href="/" class="fb__font__e">Casamia</a>
						</template>
						<template v-else>
							<span :class="`${getLanguageClass(title)}`" v-html="title"></span>
						</template>

						<slot name="title"></slot>
					</h1>
				</template>

				<!-- 카테고리 레이어(대카테고리에서만 사용) -->
				<template v-else-if="true === isCategory">
					<h2 class="fb__top__category" @click="openCategoryLayer($event)">{{title}}</h2>
				</template> 
 
				<!-- 우측 nav 버튼 -->
				<div class="fb__top__nav" v-if="topNav">
					<a href="/" class="fb__top__home" v-if="isHome">홈버튼</a>
					<button class="fb__top__search" @click="openSearch($event)" v-if="isSearch">검색버튼</button>

					<!-- app -->
					<div class="categoryModal__appBtn" v-if="isAppCheck && isAppIcon && false">
						<button id="alarmIcon" class="categoryModal__appBtn__alarm" @click="showAlarmList($event)">
							<span class="blind">알림리스트버튼</span>
						</button>

						<a href="/footer/setting" class="categoryModal__appBtn__settings">
							<span class="blind">설정 바로가기</span>  
						</a> 
					</div>
					<a href="/vrApi/IF_AR_EXHIBITION" class="fb__top__ar" v-if="isAppCheck && isCart && !isHome">AR</a>
					<a href="/shop/cart" class="fb__top__cart" v-if="isCart"> 
						<i class="fb__top__cartCnt" v-if="cartCount">{{cartCount <= 99 ? cartCount : "99+"}}</i>
					</a>  
				</div>   

				<!-- 검색 -->
				<div class="search" v-if="topSearch">
					<form class="search__form" @submit.prevent="search()">
						<fieldset>
							<legend>검색 폼</legend>
							<button type="button" class="fb__top__back serach-w " tabindex="0" @click="closeSearch">검색창 닫기</button>
							<div class="search__wrap" :class="isAppCheck && verCheck() ? 'ar' : ''">
								<span class="fb__bbs__filter-btn"></span>
								<input type="text" id="searchWord" name="searchWord" :value="searchWord" placeholder="검색어 입력" title="검색어" tabindex="0" maxlength="30" ref="searchWord" @input="$emit('update:searchWord', $event.target.value.trim())" @keydown="maxLengthCheck($event.target)" >
								<button class="btn__txt-delete" type="button" tabindex="0" v-if="searchWord && searchWord.length" @click.prevent="$emit('update:searchWord', null)"><span class="blind">검색어 삭제</span></button>
							</div>
							<button type="button" class="btn__ar" @click.prevent="goAppAR()"  v-if="isAppCheck && verCheck()">AR줄자</button>  
						</fieldset>   
					</form> 

					<template v-if="'auto' == tabs.search.activeTab">
						<template v-if="searchWords.realTime && searchWords.realTime.length">
							<ul class="autoKeyword">
								<li v-for="(word, index) of searchWords.realTime" :key="`${index}-auto-word`">
									<a :href="'/shop/search?query=' + [[ word.KEYWORD.replace(/(<([^>]+)>)/ig,'') ]]">
										<template v-for="(splitWords, index2) in getRealTimeSearchSplit(word.KEYWORD, searchWord)">
											<template v-if="splitWords">
												<b class="font-bold" v-if="searchWord.toLowerCase() == splitWords.toLowerCase()" :key="`${index}-${index2}-auto-word`" v-html="splitWords"></b>
												<span :key="`${index}-${index2}-auto-word`" v-else v-html="splitWords"></span>
											</template>
										</template>
									</a> 
								</li>
							</ul> 
						</template>
					</template>

					<div class="allSearch" v-else>
						<div class="allSearch__tabs">
							<input type="radio" name="searchKeyword" class="blind" id="searchKeywordBest" value="best" v-model="tabs.search.activeTab">
							<label for="searchKeywordBest">베스트 검색어</label>

							<input type="radio" name="searchKeyword" class="blind" id="searchKeywordRecent" value="recent" v-model="tabs.search.activeTab">
							<label for="searchKeywordRecent">최근 검색어</label>
						</div>

						<!-- 키워드 -->
						<div class="allSearch__cont">
							<!-- 베스트 키워드 -->
							<template v-if="'best' === tabs.search.activeTab">
								<!-- 10개 노출 -->
								<ul class="keyword best" v-if="bestSearchWords && bestSearchWords.length">
									<li class="keyword__list" v-for="(word, index) of bestSearchWords" :key="`${index}-best-word`">
										<a :href="`/shop/search?query=${word.KEYWORD}`">
											<span>{{word.RANKING}}</span> {{word.KEYWORD}}
										</a>
										<span class="keyword__list__rank">
											<i :class="getChangeRankingClass(word)" v-if="0 !== getChangeRanking(word)"></i>
											<template v-if="0 == getChangeRanking(word)">
												-
											</template> 
											<template v-else>
												{{getChangeRanking(word)}}
											</template>
										</span> 
									</li>
								</ul>
							</template>

							<!-- 추천 키워드 -->
							<template v-else-if="'recent' === tabs.search.activeTab">
								<template v-if="searchWords.recent && searchWords.recent.length">
									<!-- 10개 노출 -->
									<ul class="keyword recent">
										<li class="keyword__list" v-for="(searchedWord, index) of searchWords.recent" :key="`${index}-recent-word`">
											<a class="search__word__text" :href="`/shop/search?query=${searchedWord}`">{{searchedWord}}</a>
											<button type="button" class="btn__del" @click="removeRecentWord($event, searchedWord)"></button>
										</li>
									</ul>

									<div>
										<button type="button" @click="removeRecentWords($event)">검색어 전체삭제</button>
									</div>
								</template>

								<div class="data-null fb__font__t" v-else>최근 검색어가 없습니다.</div>
							</template>
						</div>
					</div>
				</div>
			</div>

			<nav class="fb__gnb swiper-container" :class="{ssgDay: isSsgDay }" v-if="isGnb && fetches.menus === true">
				<ul class="fb__gnb__inner swiper-wrapper">
					<li class="fb__gnb__menu swiper-slide" v-for="(menu, index) in menus" :key="index" :class="{ 'on' : menu.MO_LINK_URL == paramUrl ? true : false } ">
						<!-- menu.ID == 1값 삭제
						<template v-if="'1' == menu.ID">
							<a class="fb__gnb__link" :href="menu.MO_LINK_URL" v-html="menu.TITLE" style="color:#f98d89"></a>
						</template>
						-->
						<template>
							<a class="fb__gnb__link" :href="menu.MO_LINK_URL" v-html="menu.TITLE"></a>
						</template>
					</li>
				</ul>
			</nav>
		</div>

		<alert-component :message="alert.message" aria-modal="알림" @close-alert="alert.isOpen = false" v-if="alert.isOpen"></alert-component>

		<modal-component :classes="modals.category.classes" :title="modals.category.title" :width="modals.category.width" :height="modals.category.height" :is-background-close="modals.category.isBackgroundClose" :is-close-button="modals.category.isCloseButton" @close-modal="closeModal($event, 'category')" v-if="modals.category.isOpen">
			<template v-slot:default>
				<section class="categoryModal__wrapper">
					<div class="categoryModal__header">
						<h2 class="categoryModal__header__link">
							<template v-if="fetches.mypageInfo"> 
								<template v-if="'Y' == login">
									<a href="/mypage/index" class="linkLogin">
										<span>반갑습니다! {{mypageInfo.AFFI_EM_COMPANY_NAME}} {{mypageInfo.SS_LOGIN_NM}}님</span>
									</a>
								</template>
								<a href="/member/login" class="linkLogin" v-else>
									<template v-if="noMemberYn == 'Y' && orderId != ''">
										<!-- TODO: 비회원 로그인 시 분기 -->
										회원 로그인으로<br> <span>혜택을 받아보세요</span>
									</template>

									<template v-else>
										<!-- 로그인 전-->
										<span>로그인 해주세요</span>
									</template>
								</a>
								<input type="hidden" value="${UR_USER_ID}">
								<input type="hidden" value="${OD_ORDER_ID}">
							</template>
						</h2>

						<div class="categoryModal__appBtn" v-if="isAppCheck">
							<button id="alarmIcon" class="categoryModal__appBtn__alarm" @click="showAlarmList($event)">
								<span class="blind">알림리스트버튼</span>
							</button>

							<a href="/footer/setting" class="categoryModal__appBtn__settings">
								<span class="blind">설정 바로가기</span>
							</a>
						</div>
					</div>

					<div class="categoryModal__contents" :class="'Y' == login ? 'more' : ''">
						<strong class="categoryModal__contents__title">카테고리</strong>
						<ul class="categoryModal__menu">
							<li class="categoryModal__menu__box" v-for="(menu, index) in allMenus" :key="index">
								<a class="categoryModal__menu__link" :href="'/shop/index?IL_CTGRY_ID='+ menu.IL_CTGRY_ID">{{menu.CTGRY_NAME}}</a>
							</li>
						</ul>
					</div>

					<ul class="categoryModal__subMenu">
						<li>
							<a href="/agency/agencyInfo">대리점개설안내</a>
						</li>
						<li>
							<a href="/customer/storeInformation">매장안내</a>
						</li>
						<li>
							<a href="/csCenter/index">고객센터</a>
						</li>
					</ul>
				</section>
			</template>
		</modal-component>
    </header>
</template>

<script>
	import eventBus from "../mixins/event-bus";
	import history from "../mixins/history";
	import alertComponent from "./alert-component.vue";

    export default {
		name: "header-component",
		mixins: [eventBus, history], 
		components: { alertComponent },
        props: {
            isBack: {
                type: Boolean,
                default: true
			},

			isBackOverride: {
				type: Boolean,
                default: false
			},

			isLnb: {
				type: Boolean,
				default: false,
			},

            title: {
                type: String,
                default: null
            },

            isHome: {
                type: Boolean,
                default: false
			},

            isSearch: {
                type: Boolean,
                default: true
            },

            isCart: {
                type: Boolean,
                default: true
            },

            isGnb: {
                type: Boolean,
                default: false,  //페이지 구축 동안 메인에서만 임시로 보임/ 나중에 필요한 페이지만 노출로 수정필요
			},

			searchWord: {
				type: String || null,
                default: null,
			},

			isCategory: {
				type: Boolean,
				default: false,
			},

			isAppIcon: {
				type: Boolean,
				default: false,
			}
        },

        data() {
            return {
				//상단 탑배너 
				isTopEvent : false,
				isSsgDay: false, 
				ssgDayState: { 
					comming: false,
					today: false,
				},
				login: null,

                fetches: {
					mypageInfo: false,
					menus: false,
					bestSearchWords: false,
				},

				requests: {
					mypageInfo: "",
					allMenus: {
						IL_CTGRY_ID: "0",
					},
				},

				tabs: {
					search: {
						activeTab: "best",
					}
				},

				alert: {
					width: null,
					height: null,
					isBackgroundClose: false,
					isOpen: false,
					message: null,
				},

				modals: {
					category: {
						width: null,
						height: null,
						classes: "categoryModal",
						isBackgroundClose: true,
						isOpen: false,
					},
				},

				//
				menus: [],
				allMenus: {},
				mypageInfo: null,
                beforeSt: 0,
                paramUrl: null,
                btnDelete: false,
                topNav: true,
                topTitle: true,
				topSearch: false,
				noMemberYn: '${noMemberYn}',
				orderId: '${OD_ORDER_ID}',

				// 검색
				searchKeyword: "searchKeywordBest",
				searchWords: {
					realTime: null,
					recent: null,
				},

				bestSearchWords: null,

				//대카테고리에서만 사용
				isCategoryOpen: false,

				//앱인지 체크
				isAppCheck: false,

				//카트 카운트 개수
				cartCount: null,
            }
		},

		watch: {
			topSearch: {
				handler(newValue, oldValue) {
					if(newValue) {
						this.$nextTick(() => {
							this.$refs.searchWord.focus();
							this.$refs.fbHeader.style.zIndex = "10";
						});
					}
				}
			},

			searchWord: {
				handler(newValue, oldValue) {
					const tabs = this.tabs.search;
					if("auto" !== tabs.activeTab && oldValue) tabs.activeTab = "auto";
					else if(!newValue) tabs.activeTab = "best";

					this.requestSearchWord();
				},
			},

			"searchWords.recent": {
				handler(newValue, oldValue) {
					if (newValue.length) {
						this.tabs.search.activeTab = "recent";
					}
				}
			},
		},

		beforeCreate() {
			window.VueEventBus.$on("header/search", isOpen => {
				this.topSearch = isOpen;
			});

			window.VueEventBus.$on("header/lnb", isOpen => {
				this.modals.category.isOpen = isOpen;
				this.topSearch = false;
				this.requestAllMenu();
			}); 
 
			window.VueEventBus.$on("history/loaded", isHistoryLoaded => { 
				if (isHistoryLoaded) { 

				}else{
					   
				} 
			});


			window.VueEventBus.$on("cart-count-change", refreshCount => {
				if (refreshCount) this.requestCartCount();
			})
		},

        created() {
			var link =  document.location.href; 
			
			const eventDate = moment().format("YYYYMMDDHHmmss"); 

			let now = new Date();
			let year = now.getFullYear();
			let month = ('0' + (now.getMonth() + 1)).slice(-2);
			let day = ('0' + now.getDate()).slice(-2);
			let hours = ('0' + now.getHours()).slice(-2); 
			let minutes = ('0' + now.getMinutes()).slice(-2);
			let seconds = ('0' + now.getSeconds()).slice(-2); 
			let dateString = year+month+day+hours+minutes+seconds;

			// let toDay = dateString;    
			 
			/* eventDate */    
			if(eventDate <= '20220309085959') {  
				this.isSsgDay = false; 
				this.ssgDayState.comming = true;  
				this.ssgDayState.today = false;   
				this.requestMenus(); 
			}else if( eventDate > '20220309085959' && eventDate < '20230425000000'){
				//쓱데이 기간
				this.isSsgDay = true;   
				this.ssgDayState.comming = false;   
				this.ssgDayState.today = true;  
				this.requestMenus2();    
			}else {  
				//쓱데이 기간 끝나고 난 뒤 
				this.isSsgDay = false; 
				this.ssgDayState.today = false;  
				this.ssgDayState.comming = false;  
				this.requestMenus();  
			}      
		

			// if(link.indexOf("stg2") > 0 || link.indexOf("dev2") > 0  || link.indexOf("localhost") > 0 ){
			// 	this.requestMenus();
			// } else {
			// 	//if(eventDate >= '20201026' && eventDate <= '20201101') {
			// 		this.requestMenus(); 
			// 	//} else {
			// 	//	this.requestMenus(); 
			// 	//}
			// }

			this.addEvents();
			this.requestBestSearchWords();
			this.requestRecentSearchWords();
			this.requestCartCount();
			this.paramUrl = window.location.pathname;


			if (this.isApp()) {
				this.isAppCheck = true;
			} 

			//마이페이지에서만 확인
			if (window.location.pathname.indexOf("/mypage/index") != "-1") {
				//앱 알림 목록 읽을 것이 있는지 확인
				if (this.getUserAgent() === "AOS") {
					window.JavascriptInterface.getPushRead();
				}
				else if (this.getUserAgent() === "IOS") {
					window.webkit.messageHandlers.getPushRead.postMessage("");
				}
			}
        },

        mounted() {
			window.addEventListener('scroll', this.topScroll); 
			this.requestMypageInfo(); 
	 
			this.$nextTick(function () {
				
				//스크롤 체크 
				if( this.isSsgDay == true ){
					const swiperEle = document.querySelector( ".fb__gnb" );  
					if( swiperEle == null || swiperEle == undefined ) return;
 					this.gnbScrollOffset();       
				}

				if (this.getCookie("topEvents") == ""){  
						this.getUrlName();     
				}  

				if( this.isTopEvent == false ){ 
					let fbHeader = document.querySelector( ".fb__header__inner" ).clientHeight;	
					let fbHeaderPaddingTop = document.querySelector( ".fb__header" );
				}  
			});  
        }, 
 
        methods: { 
			gnbScrollOffset: function(){ 
				let swiperEle = document.querySelectorAll( ".fb__gnb__menu " );
				let swiperTotalWidth = document.querySelector( ".fb__gnb" ).clientWidth;   
					swiperEle.forEach(function(item, index){
						if( item.classList.contains( "on" )){
							let swiperControl = document.querySelector( ".fb__gnb " ); 
							if( ( Math.floor( swiperTotalWidth / 2)) <   item.offsetLeft ){
								swiperControl.scrollLeft = item.offsetLeft;
							}
						} 
					});
			}, 
 

			getUrlName: function(){ 
				let _this = this;
				let urlNameSplit = window.location.pathname.split('/').pop();     
				let fbHeaderTopMius = document.querySelector( ".fbEvent_top");    
				let urlArr = [ "index", "goodsEvent", "pet_guud", "beauty_guud", "campo", "goodsView" ]; 

				const eventDate = moment().format("YYYYMMDDHHmmss"); 
				let now = new Date();
				let year = now.getFullYear();
				let month = ('0' + (now.getMonth() + 1)).slice(-2);
				let day = ('0' + now.getDate()).slice(-2);
				let hours = ('0' + now.getHours()).slice(-2); 
				let minutes = ('0' + now.getMinutes()).slice(-2);
				let seconds = ('0' + now.getSeconds()).slice(-2); 
				let dateString = year+month+day+hours+minutes+seconds; 

				// let toDay = dateString;  

				// 운영나갈때 eventDate ==> 테스트 toDay
				if( eventDate >= '20211024235959' && eventDate <= '202110250959' ){   
					_this.isTopEvent = false;  
					return;
				}else{
					let urlArrCheck = urlArr.filter( function ( urlName ){ 
						if( urlNameSplit == urlName ){  
							_this.isTopEvent = false;  
							_this.$nextTick(() => {  
									let targetGoing = document.getElementById( "targetGoing" );   
									let wW = window.innerWidth; 
									let fbHeaderTopMius = document.querySelector( ".fbEvent_top"); 
									let mainSwiper =  document.querySelector( ".fb__gnb" );  
									let etcGnb =  document.querySelector( ".fb__top" ).clientHeight;  
									return;  
									if( mainSwiper == null && _this.isTopEvent ){     
										targetGoing.style.paddingTop = Math.floor( (fbHeaderTopMius.clientHeight + etcGnb) / wW  * 100 )  + "vw";   
									}else{  
										let fbHeaderTwoTotal = document.querySelector( ".fb__top" ).clientHeight + mainSwiper.clientHeight + document.querySelector( ".fb__header__inner__event" ).clientHeight;
										targetGoing.style.paddingTop = Math.floor( ( fbHeaderTwoTotal / wW ) * 100) + "vw";   
									}  
							});  
							
						}else{
							const fbHeader = document.getElementById( "targetGoing" );   
							const gbHeaderLogo = document.querySelector( ".fb__header__inner" ).clientHeight;
							let wW = window.innerWidth;      
							// fbHeader.style.paddingTop =  Math.floor( ( gbHeaderLogo / wW ) * 100) + "vw";     
						}  
					})
				}
			},   
			 
			async requestMenus() {
            	try {
                    const response = {
                        "IMAGE_SERVER_HOST":"",
                        "RETURN_CODE":"000000000",
                        "sAppType":"",
                        "sAppDeviceId":"",
                        "sAppVersion":"",
                        "placeholder":null,
                        "rows":[{
                            "MO_LINK_URL": "/index",
                            "TITLE": "HOME",
                            "PC_LINK_URL": "/index",
                            "ID": 0
						},{
                            "MO_LINK_URL": "/todayDeal/index",
							"TITLE": "SALE",
							"PC_LINK_URL": "/todayDeal/index",
							"ID": 99999
						},{
                            "MO_LINK_URL": "/ranking/index",
                            "TITLE": "BEST",
                            "PC_LINK_URL": "/ranking/index",
                            "ID": 2 
                        },{
                            "MO_LINK_URL":"/lineup/index",
                            "TITLE":"BRAND",
                            "PC_LINK_URL":"/lineup/index",
                            "ID": 4
                        }, {
                            "MO_LINK_URL":"/event/goodsEvent",
                            "TITLE":"EVENT",
                            "PC_LINK_URL":"/event/goodsEvent",
                            "ID": 5 
                        },{
                            "MO_LINK_URL":"/ithome/index", 
                            "TITLE": "MAGAZINE", 
                            "PC_LINK_URL":"/ithome/index",  
                            "ID": 3
                        }],
                        "sUserAgentType":"pc", 
                        "isSession":"N",
                        "LOGIN_NOT_MSG":"로그인을 해주세요."
					};

					// 200707 : 레드마인 3409 요청
					// ,{
					// 	"MO_LINK_URL":"/shop/dealList",
					// 	"TITLE":"DEAL",
					// 	"PC_LINK_URL":"/shop/dealList",
					// 	"ID": 6
					// }, {
					// 	"MO_LINK_URL":"/casamia",
					// 	"TITLE":"<img src='/mobile/assets/images/header/logo_casamia.png' alt='casamia'>",
					// 	"PC_LINK_URL":"/casamia",
					// 	"ID": 7
					// }

					const rows = response.rows;

                    if(rows && rows.length) this.menus = rows;

                    this.fetches.menus = true;

                    this.$nextTick(function () {
                        // const swiper = new Swiper('.fb__gnb.swiper-container', {
						// 	slidesPerView: "auto",
                        // });
                    });
                }
                catch(ex) {
                    this.fetches.menus = 'error';
                }
			},


			async requestMenus2() {
            	try {
                    const response = {
                        "IMAGE_SERVER_HOST":"",
                        "RETURN_CODE":"000000000",
                        "sAppType":"",
                        "sAppDeviceId":"",
                        "sAppVersion":"",
                        "placeholder":null,
                        "rows":[{
                            "MO_LINK_URL": "/index",
                            "TITLE": "HOME",
                            "PC_LINK_URL": "/index",
                            "ID": 0
						},{
                            "MO_LINK_URL": "/todayDeal/index",
							"TITLE": "SALE",
							"PC_LINK_URL": "/todayDeal/index",
							"ID": 99999
						},{
                            "MO_LINK_URL": "/ranking/index",
                            "TITLE": "BEST",
                            "PC_LINK_URL": "/ranking/index",
                            "ID": 2
                        },{ 
                            "MO_LINK_URL": "/event/goodsEventDetail?DP_DISPLAY_ID=11582&TYPE=3&SUB_TYPE=4",
							"TITLE": "OUTLET",
							"PC_LINK_URL": "/event/goodsEventDetail?DP_DISPLAY_ID=11582&TYPE=3&SUB_TYPE=4",
							"ID": 9999 
						},{
                            "MO_LINK_URL":"/lineup/index",
                            "TITLE":"BRAND", 
                            "PC_LINK_URL":"/lineup/index",
                            "ID": 4
                        }, {
                            "MO_LINK_URL":"/event/goodsEvent",
                            "TITLE":"EVENT",
                            "PC_LINK_URL":"/event/goodsEvent",
                            "ID": 5 
                        },{
                            "MO_LINK_URL":"/ithome/index",
                            "TITLE": "MAGAZINE", 
                            "PC_LINK_URL":"/ithome/index", 
                            "ID": 3
                        }],
                        "sUserAgentType":"pc",
                        "isSession":"N",
                        "LOGIN_NOT_MSG":"로그인을 해주세요."
					};

					// 200707 : 레드마인 3409 요청
					// ,{
					// 	"MO_LINK_URL":"/shop/dealList",
					// 	"TITLE":"DEAL",
					// 	"PC_LINK_URL":"/shop/dealList",
					// 	"ID": 6
					// }, {
					// 	"MO_LINK_URL":"/casamia",
					// 	"TITLE":"<img src='/mobile/assets/images/header/logo_casamia.png' alt='casamia'>",
					// 	"PC_LINK_URL":"/casamia",
					// 	"ID": 7
					// }

					const rows = response.rows;

                    if(rows && rows.length) this.menus = rows;

                    this.fetches.menus = true;

                    this.$nextTick(function () {
                        // const swiper = new Swiper('.fb__gnb.swiper-container', {
						// 	slidesPerView: "auto",
                        // });
                    });
                }
                catch(ex) {
                    this.fetches.menus = 'error';
                }
			},

			async requestBestSearchWords() {
				try {
					const response = await this.$store.dispatch("network/request", {
						method: "post",
						url: "/search/hotSearch",
					});
					const rows = response.hotObj.hotResult;

					if(rows && rows.length) {
						rows.sort((a, b) => {
							return parseInt(a.RANKING) - parseInt(b.RANKING);
						});

						this.bestSearchWords = rows;
					}

					this.fetches.bestSearchWords = true;
				}
				catch(ex) {
					this.fetches.bestSearchWords = "error";
				}
			},

			async requestRecentSearchWords() {
				let recentWords = window.localStorage.getItem("recent-words");

				recentWords = recentWords ? recentWords.split("|") : [];
				this.searchWords.recent = recentWords.reverse();
			},

			async requestSearchWord() {
				const text = this.searchWord;
				try {
					const response = await this.$store.dispatch("network/request", {
						id: this.$options.name,
						method: "post",
						url: "/search/smartMaker", 
						data: {
							searchTerm: this.searchWord
						},
						isPreviousCancel: true,
					});
					const rows = response.autoObj && response.autoObj.autoResult ? response.autoObj.autoResult : null;

					this.searchWords.realTime = rows;
				}
				catch(ex) {
					console.error("requestSearchWord exception...", ex);
				}
			},

			async requestMypageInfo() {
				try {
					const parameters = this.requests.mypageInfo;
					const response = await this.$store.dispatch("network/request", {
						method: "post",
						url: "/biz/mypage/mybenefit/getMyBenefit",
						data: parameters
					});

					if (response) {
						this.mypageInfo = response;
						this.login = response.isSession;
					}

					this.fetches.mypageInfo = true;
				}
				catch(ex) {
					console.error("requestMypageInfo exception... ", ex);
				}
			},

			async requestAllMenu() {
				const parameter = this.requests.allMenus;
				try {
					const response = await this.$store.dispatch("network/request", {
						method: "post",
						url: "/comn/il/dispCtgry/getUseDispCtgrys",
						data: parameter,
					});

					const rows = response.rows;

					if(rows && rows.length) this.allMenus = rows;
				}
				catch(ex) {
					console.error(ex)
				}
			},

			//
			addEvents() {
				this.$parent.$on("open-search", (payload) => {
					this.openSearch(payload);
				});
			},

            historyBackInit(e) {
				if(this.isBackOverride) this.$emit("back", e);
				else window.history.back();
            },

			// getter
            getLanguageClass(text) {
                const langExp = /[a-zA-Z]/;
                if (langExp.test(text)) return 'fb__font__e';
                else return '';
			},

			getChangeRankingClass(item) {
				const changeRanking = parseInt(item.PREV_RANK) - parseInt(item.RANKING);
				let state = null;

				if(0 === changeRanking) state = "";
				else if(0 < changeRanking) state = "list-up";
				else if(0 > changeRanking) state = "list-down";

				return state;
			},

			getChangeRanking(item) {
				return Math.abs(parseInt(item.PREV_RANK) - parseInt(item.RANKING));
			},

			getRealTimeSearchSplit(word, searchWord) {
				const regex = new RegExp(`(.*)(${searchWord})(.*)`, "i");
				const realTimeSearchSplit = word.match(regex);

				return realTimeSearchSplit && realTimeSearchSplit.length ? realTimeSearchSplit.slice(1, realTimeSearchSplit.length) : null;
			},

			moveLogin() {
				const currentPage = window.location.pathname + window.location.search;
				window.location.href = "/member/login?reurl=" + currentPage;
			},

			// event
			logout() { 
				this.requestLogOut(); 
			},

		
            topScroll() { 
				
				if (!document.getElementById("targetGoing").classList.contains("isGoing")) { 
					const fbHeader = this.$refs.fbHeader;  
					const _current = document.documentElement.scrollTop;    
					const fbHeaderH = fbHeader.clientHeight;

					const targetGoing = document.getElementById( "targetGoing" );  //총 헤더 높이
					const targetGoingH = targetGoing.clientHeight;
					const wW = window.innerWidth; 
					const fbHeaderBanner = this.$refs.topEventBanners.clientHeight;   //헤더영역 추가 배너
					const fbHeaderTopMius = document.getElementById( "fbHedaer");    //헤더 영역 마이너스 
					const fbHeaderTwoTotal = document.querySelector( ".fb__top" ).clientHeight + document.querySelector( ".fb__header__inner__event" ).clientHeight ;
					if ( _current > this.beforeSt) {  
						if( this.isTopEvent ){  
							targetGoing.style.paddingTop  =  Math.floor((targetGoingH / wW ) * 100) + "vw";      
							fbHeaderTopMius.style.top = Math.floor( -( fbHeaderTwoTotal / wW ) * 100) + "vw";  
						}else if( !this.isTopEvent ){ 
							let fbHeaderOffsetTop = document.querySelector( ".fb__top" ).clientHeight;
							fbHeaderTopMius.style.top = Math.floor( -(fbHeaderOffsetTop / wW ) * 100) + "vw";  
						} 
					}else{
						fbHeaderTopMius.style.top = 0;   
					} 
 
					this.$emit("change-header", { 
						rect: fbHeader.getBoundingClientRect(),
						offsetHeight: fbHeader.offsetHeight,
						clientHeight: fbHeader.clientHeight,
						scrollHeight: fbHeader.scrollHeight,
					});

					this.beforeSt = _current <= 0 ? 0 : _current;
				}
            },

            openSearch () {
                this.topSearch = true;
                document.body.classList.add("scroll-lock");

                this.isOpen = !this.isOpen;
				window.VueEventBus.$emit("dockbar-visible", !this.isOpen);
            },

            closeSearch () {
                this.topSearch = false;
                document.body.classList.remove("scroll-lock");

                this.isOpen = !this.isOpen;
				window.VueEventBus.$emit("dockbar-visible", !this.isOpen);
				this.$refs.fbHeader.style.zIndex = "";
			},

			openCategoryLayer(e) {
				
				let topBanner = document.querySelector( ".fb__header__inner__event" );
				if( this.isCategoryOpen == false && this.isTopEvent == true ){
					topBanner.style.marginTop = "-16vw";  
				}else{
					topBanner.style.marginTop = 0;  
				} 
				this.isCategoryOpen = !this.isCategoryOpen; 

				this.$emit("open-category-layer", {
					e,
					isOpen: this.isCategoryOpen
				})
			},

			openModal ( type ) {
				const modal = this.modals[type];

				modal.isOpen = true;
				switch ( type ) {
					case "category":
						this.requestAllMenu();
					break;
				}
			},

			closeModal ( payload, type ) {
				const modal = this.modals[type];

				modal.isOpen = false; 
			},
 
			closeTodayModal( payload, type ){ 
				
				let targetGoing = document.getElementById( "targetGoing" );  
				if ( type == "topEvent" ){
					const wW = window.innerWidth;
					const fbHeaderSwiper = document.querySelector( ".fb__gnb" );
					const gbHeaderLogo = document.querySelector( ".fb__top" );
					this.setCookie("topEvents", "done", 1);
					this.isTopEvent = false;    
					if( !this.isTopEvent ){    
						if( fbHeaderSwiper ){
							targetGoing.style.paddingTop  =  Math.floor((( gbHeaderLogo.clientHeight + fbHeaderSwiper.clientHeight)  / wW ) * 100) + "vw";     
						}else{ 
							targetGoing.style.paddingTop  =  Math.floor(( gbHeaderLogo.clientHeight  / wW ) * 100) + "vw";   
						}  
					}    
				}   
			},
 
			setCookie(name, value, expiredays) {
				const todayDate = new Date(); 
				
				todayDate.setDate(todayDate.getDate() + expiredays);
				document.cookie = name + "=" + escape( value ) + "; path=/; expires=" + todayDate.toGMTString() + ";";
			},
 
			getCookie(name) {
				var nameOfCookie = name + "=";  
				var x = 0;  
				let endOfCookie; 
 
				while ( x <= document.cookie.length ) {
					var y = x + nameOfCookie.length;
					
					if ( document.cookie.substring( x, y ) == nameOfCookie ) {
						if ( (endOfCookie = document.cookie.indexOf( ";", y )) == -1 )
							endOfCookie = document.cookie.length;
						return unescape(document.cookie.substring(y, endOfCookie));
					}
					
					x = document.cookie.indexOf(" ", x) + 1;
					
					if ( x == 0 ) break;
				}
				
				return "";
			},

			// events
			search() {
				let recentWords = window.localStorage.getItem("recent-words");
				let searchWord = null;
				let existsIndex = null;

				recentWords = recentWords ? recentWords.split("|") : [];
				searchWord = this.$refs["searchWord"].value;

				if (!searchWord || !searchWord.replace(/(\s)+/, '')) {
					this.alert.message = "검색어는 1글자 이상 입력해주세요.";
					this.alert.isOpen = true;
				}
				else {
					existsIndex = recentWords.findIndex(v => v == searchWord);

					if (0 <= existsIndex) recentWords.splice(existsIndex, 1);
					else if (recentWords.length && 10 <= recentWords.length) recentWords.splice(0, 1);

					recentWords.push(searchWord);



					window.localStorage.setItem("recent-words", recentWords.join("|"));
					window.location.href = "/shop/search?query=" + encodeURIComponent(searchWord);
				}
			},

			removeRecentWord(e, text) {
				let recentWords = window.localStorage.getItem("recent-words");

				recentWords = recentWords ? recentWords.split("|") : [];
				recentWords.splice(recentWords.findIndex(v => v == text), 1);
				this.searchWords.recent = recentWords.reverse();
				window.localStorage.setItem("recent-words", recentWords.join("|"));
			},

			removeRecentWords(e) {
				this.searchWords.recent = null;
				window.localStorage.removeItem("recent-words");
			},

			async requestCartCount() {
				try {
					const response = await this.$store.dispatch("network/request", {
						method: "post",
						url: "/biz/cs/cart/getCartCount",
					})

					if (response && response.cartCount) {
						this.cartCount = response.cartCount;
					}
				}

				catch(ex) {
					console.error(ex, "requestCartCount has exception...");
				}
			},

			//앱
			isApp() {
				if (/(CasamiaAOSApp|CasamiaIOSApp)/ig.test(window.navigator.userAgent)) return true;
				else return false;
			},

			getUserAgent() {
				if (/(CasamiaAOSApp)/ig.test(window.navigator.userAgent)) return "AOS";
				else if (/(CasamiaIOSApp)/ig.test(window.navigator.userAgent)) return "IOS";
			},

			getAppVersion() {
				const useragent = window.navigator.userAgent;
				let arr = null;

				if (this.isApp()) {
					if(this.getUserAgent() == "IOS" || this.getUserAgent() == "AOS") {
						arr = useragent.split("//");
						return arr[arr.length - 1];
					}
				}
			},
 
			verCheck() {
				const appVersion = this.getAppVersion(); 

				if (appVersion && Number(appVersion.replace(/\./gi,"")) > 101) return true;
				else return false ; 
			}, 

			//앱 알림리스트 레이어 보여주기
			showAlarmList(e) {
				if (this.getUserAgent() === "AOS") window.JavascriptInterface.pushListBtnClick();
				else if (this.getUserAgent() === "IOS") window.webkit.messageHandlers.pushListBtnClick.postMessage("");
			}, 

			goAppAR() {
				if (this.getUserAgent() === "AOS") window.JavascriptInterface.arBtnClick();
				else if (this.getUserAgent() === "IOS") window.webkit.messageHandlers.arBtnClick.postMessage("");
			},

			maxLengthCheck(object) {
				if(object.value.length > object.maxLength) {
					object.value = object.value.slice(0, object.maxLength);
				}
			},
        } 
	}

	//앱 알림 목록 콜백함수
	window.setPushRead = function(response) { 
		if (response == "Y") { //알림 확인할 것이 있을때
			document.getElementById("alarmIcon").classList.add("hasAlarm");
		}
		else { //알림 목록 다 확인했을때
			document.getElementById("alarmIcon").classList.remove("hasAlarm");
		}
	}
</script>