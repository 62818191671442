<template>
    <div class="fb__left-nav">
         <h2 class="fb__left-nav__title">
            <a href="/mypage/index" title="마이페이지" class="fb__font__e">
               MY PAGE
            </a>
        </h2>
        <div class="fb__mypage">
            <div class="fb__mypage__left">
                <div class="fb__mypage__left__info">
                    <p v-if="mypageInfo.SS_LOGIN_NM">
                        {{mypageInfo.SS_LOGIN_NM}}님<br />
                        안녕하세요!
                    </p>
					<p v-else>
						비회원 주문조회
					</p>
                </div>
                <ul class="mypage__left" v-if="mypageInfo.SS_LOGIN_NM">
                    <li class="mypage__left__menu">
                        <h3 class="mypage__left__subtitle">나의 쇼핑내역</h3>
                        <a href="/mypage/order/orderHistory" :class="pageName == 'order-history' ? 'mypage__left--active' : null">
                            <span> 주문조회</span>
                        </a>
                        <a href="/mypage/order/orderClaim" class="">

                            <span>취소/반품 조회</span>
                        </a>
                    </li>
                    <li class="mypage__left__menu">
                        <h3 class="mypage__left__subtitle">나의 혜택관리</h3>
                        <a href="/mypage/mybenefit/myPoint" class="">
                            <span> 나의 포인트</span>
                        </a>
                        <a href="/mypage/myCoupon/getMyCouponMain" class="">

                            <span>나의 쿠폰</span>
                        </a>
                    </li>
                    <li class="mypage__left__menu">
                        <h3 class="mypage__left__subtitle">나의 관심내역</h3>
                        <a href="/mypage/wishlist/recentlyList" class="">
                            <span> 최근 본 상품</span>
                        </a>
                        <a href="/mypage/wishlist/productList" class="">
                            <span>쇼핑찜 내역</span>
                        </a>
                    </li>

                    <li class="mypage__left__menu">
                        <h3 class="mypage__left__subtitle">나의 커뮤니티</h3>
                        <a href="/mypage/community/inquiryList" class="">
                            <span> 1:1 문의 내역</span>
                        </a>
                        <a href="/mypage/community/qnaList" class="">

                            <span>나의 상품 문의</span>
                        </a>
                        <a href="/mypage/myFeedback/getMyFeedbackMain" class="">

                            <span>나의 상품 리뷰</span>
                        </a>
                    </li>

                    <li class="mypage__left__menu">
                        <h3 class="mypage__left__subtitle">회원정보관리</h3>
                        <a href="/mypage/memberInfo/memberInfoDelivery" class="">

                            <span>배송지 관리</span>
                        </a>
                    </li>
                </ul>
				<ul v-else>
					<li class="mypage__left__menu">
						<h3 class="mypage__left__subtitle">나의 쇼핑내역</h3>
						<a :href="'/mypage/order/orderHistory?OD_ORDER_ID=' + mypageInfo.orderId" v-bind:class="{'mypage__left--active' : pageName == 'orderHistory'}">
						<span>주문조회</span>
						</a>
						<a :href="'/mypage/order/orderClaim?OD_ORDER_ID=' + mypageInfo.orderId" class="">

							<span>취소/반품 조회</span>
						</a>
					</li>
				</ul>
            </div>
        </div>
	</div>
</template>
<script>
    export default {
        name : "mypage-left-component",

        data() {
            return {
                isLogin: "",
            }
        },

		props: {
            mypageInfo: {
                type: Object | null,
				required: true
			},
			
            pageName: {
                type: String,
            }
		},

        methods: {
            
		},
    }
</script>
<style lang="scss" scoped>
	.fb {
		&__mypage {

			&__left {

				&__info {
					height: 205px;
					padding-top:  38px;
					color: #000;
					font: {
						size: 30px;
						weight: bold;
					};
					line-height: 1.5;
					box-sizing: border-box;
				}
			}

			.mypage__left {
				
				&__menu {
					padding: 20px 0 40px;
					border-top:  1px solid #e5e5e5;

					a {
						display: block;
						margin-top: 10px;
						color: #787878;
						font-size: 14px;
					}
				}

				&--active {
					color: #ff3e2f;
				}

				&__subtitle {
					margin-bottom: 16px;
					color: #000;
					font: {
						size: 17px;
						weight: bold;
					}
				}
			}
		}
	}
</style>