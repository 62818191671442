<template>
    <section class="fb__grid w-full flex flex-wrap">
		<div :class="marginLeft ? (0 == index % rowNumber ? `${marginTop && (1 <= (index / rowNumber)) ? 'fb__mt--' + marginTop : ''} ${marginBottom ? 'fb__mb--' + marginBottom : ''}` : `${marginTop && (1 <= (index / rowNumber)) ? 'fb__mt--' + marginTop : ''} ${marginRight ? 'fb__mr--' + marginRight : ''} ${marginBottom ? 'fb__mb--' + marginBottom : ''} ${marginLeft ? 'fb__ml--' + marginLeft : ''}`) : ''" :style="isFlexBasisNotSupport ? `width: calc(100%/${rowNumber} - ${((marginRight + marginLeft) ? (marginRight + marginLeft) : 0)}px);` : `flex-basis: calc(100%/${rowNumber} - ${((marginRight + marginLeft) ? (marginRight + marginLeft) : 0)}px);`" :key="row.index" v-for="(row, index) in rows">
			<slot :row="row" :index="index"></slot>
		</div>
	</section>
</template>
<script>
    export default {
        name : "grid-component",

		props: {
            rows: {
                type: Array | Number,
				default: () => {
					return []
				},
				required: true
			},
			
			rowNumber: {
				type: Number,
				default: 4
			},

			marginTop: {
				type: Number,
				default: 0
			},

			marginRight: {
				type: Number,
				default: 0
			},

			marginBottom: {
				type: Number,
				default: 0
			},

			marginLeft: {
				type: Number,
				default: 0
			},
        },

		data() {
			return {
				isFlexBasisNotSupport: 0 < window.navigator.userAgent.indexOf("MSIE "),
			}
		}
    }
</script>
<style scoped lang="scss">

</style>