
const Layouts = {
	data() {
		return {
			login: "",

			fetches: {
				layouts: false,
				components: {}
			},

			requests: {
				layouts: {
					DP_DISPLAY_ID: null,
					TYPE: null,
					SUB_TYPE: null
				},

				components: {},

				storeComments: {
					DISP_GUBUN: 2
				}
			},

			processes: {
				components: {}
			},

			paginations: [],

			confirm: {
				isOpen: false,
				message: null,
				type: null,
				model: null,
			},

			alert: {
				width: null,
				height: null,
				isBackgroundClose: false,
				isOpen: false,
				message: null,
			},

			itemConfirm: {
				width: null,
				height: null,
				isBackgroundClose: false,
				isBackground: true,
				isOpen: false,
				message: null,
				ok: null,
				cancel: null,
			},

			layoutItemRowCount: 10,

			//
			layouts: null,
			comments: {},
			player: {},
		}
	},

	methods: {
		/**
		 * request - common
		 * 
		 * layout component 요청
		 */
		async requestLayouts() {
			try {
				const parameters = this.requests.layouts;
				const response = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/main/getDisplayDetl",
					data: this.getExistProperties(parameters)
				});
				const rows = response.rows;
				
				this.imageRoot = response.IMAGE_SERVER_HOST;
				this.initComponent(rows);
				this.layouts = rows;
				this.fetches.layouts = true;
				this.$nextTick(() => {
					this.createSwiper();
					this.createVideo();
				});
			}
			catch(ex) {
				console.error("requestLayouts exception... ", ex);

				this.fetches.layouts = "error";
			}
		},

		/**
		 * request - file
		 * 
		 * layout component 요청
		 */
		async fileUpload(files, data) {
			const alert = this.alert;

			try {
				const response = await this.$store.dispatch('network/fileUpload', {
					method: "post",
					url: "/comn/fileUpload",
					header: {
						"Content-Type": "multipart/form-data" 
					},
					data,
					files
				});

				if("000000000" == response.RETURN_CODE) return response.addFile;
				else throw({ response, message: "file upload error..." })
			}
			catch(ex) {
				console.error("fileUpload exception...", ex);

				alert.message = ex.message || "파일 업로드 실패";
				alert.isOpen = true;

				return null;
			}
		},

		/**
		 * request - pagination
		 * 
		 * pagination 처리가 필요한 데이터 요청
		 * FIXME: 현재 데이터가 없어 테스트 불가
		 */
		async requestPaginationComponent(uuid) {
			if(this.processes.components[uuid]) return;

			const pagination = this.getPagination(uuid);
			const parameters = this.requests.components[uuid];
			let response = null;
			let rows = null;

			try {
				this.processes.components[uuid] = true;

				parameters.sPage += pagination.rowCount;
				parameters.ePage += pagination.rowCount;
						
				response = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/main/getDisplayDetl",
					data: parameters
				});
				rows = response.rows;

				this.processes.components[uuid] = false;

				return {
					code: 1,
					rows
				};
			}
			catch(ex) {
				console.error("requestPaginationComponent exception... ", ex);

				parameters.sPage -= pagination.rowCount;
				parameters.ePage -= pagination.rowCount;

				this.processes.components[uuid] = "error";

				return {
					code: -1,
					rows: null
				};
			}
		},
		
		/**
		 *	store_detail -매장안내
		 *
		 *  전시 컴포넌트와 구분하기 위해 파라미터 추가필요
		 */

		addStoreParameter(uuid, parameters){
			if (uuid == "storeComments") Object.assign(parameters, this.requests.storeComments);
		},

		/**
		 * request - comment
		 * 
		 * 댓글 요청
		 */
		async requestComment(uuid) {
			try {
				const pagination = this.getPagination(uuid);
				const parameters = this.requests.components[uuid];
				this.addStoreParameter(uuid, parameters);

				const comment = this.getComment(uuid);
				const response = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/biz/ithome/getItHomeComment",
					data: parameters
				});
				const rows = response.rows;
				let total = 0;

				if(rows && rows.length && comment) {
					total = response.TOTAL;
					pagination.total = total;
					pagination.max = Math.ceil(response.TOTAL / this.layoutItemRowCount);
					comment.rows = this.makeComments(rows);
					comment.total = total;
				}
				else {
					this.comments[uuid].rows = null;
					this.comments[uuid].total = 0;
				}
				
				this.fetches.components[uuid] = true;
			}
			catch(ex) {
				console.error("requestComment exception... ", ex);

				this.fetches.components[uuid] = "error";
			}
		},

		async requestMoreComment(uuid) {
			if(this.processes.components[uuid]) return 0;

			const parameters = this.requests.components[uuid];
			let response = null;
			let rows = null;
			let total = 0;
			
			try {
				this.processes.components[uuid] = true;

				response = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/biz/ithome/getItHomeComment",
					data: parameters
				});
				rows = response.rows;
				total = response.TOTAL;

				this.processes.components[uuid] = false;

				return {
					code: 1,
					rows,
					total
				};
			}
			catch(ex) {
				console.error("requestComment exception... ", ex);

				// TODO: 다시시도 같은 재시도 버튼을 보여줄 시 error 로 변경 및 pagination v-if 조건(false, true 'error') 3단계로 변경 필요
				// this.processes.components[uuid] = "error";
				this.processes.components[uuid] = false;

				return {
					code: -1,
					rows: null,
					total: 0
				};
			}
		},

		/**
		 * request - comment
		 * 
		 * 댓글 등록 요청
		 * 
		 * @return {Number} - 1: 성공, -1: error
		 */
		async requestPostComment(parameters, uuid) {
			this.addStoreParameter(uuid, parameters);

			try {
				if(true === this.processes.components[uuid]) return;
				this.processes.components[uuid] = true;

				const response = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/biz/ithome/addItHomeComment",
					data: parameters
				});

				if("000000000" == response.RETURN_CODE && "000000000" == response.codeChk) return 1;
				else return parseInt(response.RETURN_CODE);
			}
			catch(ex) {
				console.error("requestPutCoupon exception... ", ex);

				return -1;
			}
			finally {
				this.processes.components[uuid] = false;
			}
		},

		/**
		 * request - comment
		 * 
		 * 댓글 삭제 요청
		 * 
		 *  @return {Number} - 1: 성공, -1: error
		 */
		async requestDeleteComment(parameters, uuid) {
			this.addStoreParameter(uuid, parameters);

			try {
				if(true === this.processes.components[uuid]) return;
				this.processes.components[uuid] = true;

				const response = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/biz/ithome/delComment",
					data: parameters
				});
				const rows = response.rows;

				if("000000000" == response.RETURN_CODE) return 1;
				else return parseInt(response.RETURN_CODE);
			}
			catch(ex) {
				console.error("requestPutCoupon exception... ", ex);

				return -1;
			}
			finally {
				this.processes.components[uuid] = false;
			}
		},

		/**
		 * request - coupon
		 * 
		 * 쿠폰 등록 요청
		 * FIXME: 쿠폰 api 개발완료 시 수정 필요
		 */
		async requestPutCoupon(couponId) {
			const login = this.login;
			const alert = this.alert;
			const confirm = this.confirm;

			if("Y" === login) {
				try {
					const response = await this.$store.dispatch("network/request", {
						method: "post",
						url: "/event/couponZone/downloadCoupon",
						data: {
							PM_COUPON_ID: couponId
						}
					});

					if("000000000" === response.RETURN_CODE) {
						alert.message = "쿠폰이 발급되었습니다.<br>마이페이지에서 확인해주세요.";
						alert.isOpen = true;
					}
					else {
						alert.message = "쿠폰을 발급 중 오류가 발생하였습니다.";
						alert.isOpen = true;
					}
				}
				catch(ex) {
					console.error("requestPutCoupon exception... ", ex);

					alert.message = ex.message || "오류가 발생하였습니다.";
					alert.isOpen = true;
				}
			}
			else {
				confirm.message = "로그인이 필요합니다.<br/>로그인 하시겠습니까?";
				confirm.type = "login";
				confirm.isOpen = true;
			}
		},

		/**
		 * request - coupon
		 * 
		 * 상품 좋아요 등록 및 해제
		 */
		async requestPutWishGoods(item) {
			try {
				const response = await this.$store.dispatch("network/request", {
					method: "post",
					url: item.PRODUCT_FLAG == "F" ? "/biz/mypage/wishlist/addProductList" : "/biz/mypage/wishlist/delProductList",
					data: {
						ITEM_ID: item.IL_ITEM_ID,
						OPTION: "wishList",
						ITEM_TYPE : "I",
					}
				});

				if("000000000" == response.RETURN_CODE){
					return 1;
				}
				else return parseInt(response.RETURN_CODE);
			}
			catch(ex) {
				console.error("requestWishGoods exception...", ex);

				return -1;
			}
		},

		/**
		 * request - coupon
		 * 
		 * 상품 좋아요 등록 및 해제
		 */
		async requestPutWishBrand(item) {
			try {
				const response = await this.$store.dispatch("network/request", {
					method: "post",
					url: item.PRODUCT_FLAG == "F" ? "/biz/mypage/wishlist/addProductList" : "/biz/mypage/wishlist/delProductList",
					data: {
						ITEM_ID: item.IL_BRAND_ID,
						OPTION: "wishList",
						ITEM_TYPE : "B",
					}
				});

				if("000000000" == response.RETURN_CODE) return 1;
				else return parseInt(response.RETURN_CODE);
			}
			catch(ex) {
				console.error("requestWishGoods exception...", ex);

				return -1;
			}
		},
		

		/**
		 * init - common
		 * 
		 * 컴포넌트 별 pagination, requests, fetches 등...
		 * 공통 데이터 초기화
		 */
		initComponent(layouts) {
			if(!layouts || !layouts.length) return;

			for(let i = 0, maxCnt = layouts.length; i < maxCnt; i++) {
				const layout = layouts[i];
				const detail = layout.DETL;
				let pagination = null;
				let parameters = null;
				let uuid = window.uuidV4();

				// common
				let total = 0;
				
				switch(layout.KIND_TYPE) {
					case "ITEM":
						if(!detail) continue;

						total = detail.total ? detail.total.TOTAL : 0;
						pagination = {
							type: "item",
							id: uuid,
							current: 1,
							rowCount: this.layoutItemRowCount,
							total,
							max: Math.ceil(total / this.layoutItemRowCount),
						};
						parameters = {
							sPage: 1,
							ePage: 10,
							// DP_DISPLAY_ID: layout.DP_DISPLAY_ID,
							DP_DISPLAY_DETL_ID: layout.DP_DISPLAY_DETL_ID,
							TYPE: layout.RETURN_TYPE,
							SUB_TYPE: layout.RETURN_SUB_TYPE
						};
						this.paginations.push(pagination);
						this.$set(this.fetches.components, uuid, true);
						this.$set(this.processes.components, uuid, false);
						this.$set(this.requests.components, uuid, parameters);
					break;
					case "COMMENTS":
						total = detail.total;
						pagination = {
							type: "comment",
							id: uuid,
							current: 1,
							rowCount: this.layoutItemRowCount,
							total,
							max: Math.ceil(total / this.layoutItemRowCount),
						};
						parameters = {
							sPage: 1,
							ePage: this.layoutItemRowCount,
							DP_DISPLAY_ID: layout.DP_DISPLAY_ID,
						};
						if(detail) detail.comments = this.makeComments(detail.comments);

						this.paginations.push(pagination);
						this.$set(this.fetches.components, uuid, true);
						this.$set(this.processes.components, uuid, false);
						this.$set(this.requests.components, uuid, parameters);
						this.$set(this.comments, uuid, {
							id: layout.DP_DISPLAY_ID,
							input: {
								comment: null,
								files: [],
							},
							rows: detail.comments || [],
							total
						});
					break;
				}

				layout.uuid = uuid;
				layout.nav = null;
				layout.selectedNav = null;
			}
		},

		/**
		 * getter - common
		 * 
		 * 댓글이 없을 시 dummy data
		 * 댓글 컴포넌트(comments-component) 사용하기 위해 필요
		 */
		getLayout(uuid) {
			return this.layouts.find(v => v.uuid == uuid);
		},

		/**
		 * getter - pagination
		 * 
		 * id와 일치하는 pagination 데이터 반환
		 */
		getPagination(id) {
			return this.paginations.find(v => v.id == id);
		},

		/**
		 * getter - comment
		 * 
		 * 댓글이 없을 시 dummy data
		 * 댓글 컴포넌트(comments-component) 사용하기 위해 필요
		 */
		getDummyComments() {
			return {
				id: null,
				input: {
					comment: null,
					files: []
				},
				rows: []
			}
		},

		/**
		 * getter - comment
		 * 
		 * uuid와 일치하는 댓글 반환
		 */
		getComment(uuid) {
			const comment = this.comments[uuid];
			
			return comment ? comment : null;
		},
		
		/**
		 * uuid와 일치하는 첨부파일 반환
		 */
		getCommentFiles(uuid) {
			const comment = this.comments[uuid];
			
			return comment ? comment.input.files : [];
		},

		/**
		 * 기존 comments에 신규 comments 를 합친다.
		 */
		mergeComments(payload) {
			const { uuid, mergeComments, total } = payload;
			const pagination = this.getPagination(uuid);
			const comment = this.getComment(uuid);

			if(mergeComments && mergeComments.length && comment) {
				pagination.total = total;
				pagination.max = Math.ceil(total / pagination.rowCount);
				comment.rows = comment.rows.concat(this.makeComments(mergeComments));
				comment.total = total;
			}
		},

		/**
		 * make - comment
		 * 
		 * 댓글과 답글이 같은 레벨로 내려오므로,
		 * 답글을 댓글 안으로 넣어주는 작업이 필요
		 */
		makeComments(data) {
			if(data && data.length) {
				const originalComments = _.cloneDeep(data);
				const comments = originalComments.filter(v => 1 == v.COMMENT_TYPE);
				const replies = originalComments.filter(v => 2 == v.COMMENT_TYPE);

				for(let i = 0, maxCnt = comments.length; i < maxCnt; i++) {
					const comment = comments[i];
					const childReplies = replies.filter(v => comment.DP_DISPLAY_BOARD_ID == v.UPPER_ID);

					if(childReplies && childReplies.length) {
						for(let j = 0, maxCntJ = childReplies.length; j < maxCntJ; j++) {
							originalComments.splice(originalComments.findIndex(v => v == childReplies[j]), 1);
						}

						comment.replies = childReplies;
					}
				}

				// return originalComments;
				// FIXME: 데이터가 이상하게 내려오고 있어 아래와 같이 임시 처리
				return originalComments.filter(v => 1 == v.COMMENT_TYPE);
			}
			else {
				return null
			};
		},

		/**
		 * make - video
		 * 
		 * video player 생성
		 */
		createVideo() {
			const layouts = this.layouts;

			if(!layouts || !layouts.length) return;
			
			const deviceWidth = window.screen.width;
			const videos = layouts.filter(v => "VIDEO" === v.KIND_TYPE);
			let video = null;
			let player = null;

			for(let i = 0, maxCnt = videos.length; i < maxCnt; i++) {
				video = videos[i];

				if(!video.VIDEO_URL) continue;

				player = new Player(video.uuid, {
					id: video.VIDEO_URL,
					width: deviceWidth * (deviceWidth / 670)
				});

				this.$set(this.player, video.uuid, player);
			}
		},

		/**
		 * make - video
		 * 
		 * swiper 생성
		 */
		createSwiper() {},

		/**
		 * events - common
		 * 
		 * 데이터 오류 시 request 다시시도
		 */
		async reload(e, type, data) {
			if(e && e.target) e.target.disabled = true;

			switch(type) {
				case "layouts":
					this.fetches.layouts = false;
					await this.requestLayouts();
				break;
				case "item":
					this.requests.components[data].sPage = 1;
					this.requests.components[data].ePage = this.layoutItemRowCount;
					this.fetches.components[data] = false;
					this.requestPaginationComponent(data);
				break;
				case "paging":
					this.requestPaginationComponent(data);
				break;
				case "comment":
					this.fetches.components[data] = false;
					this.requestComment(data);
				break;
				default:
				break;
			}
			
			if(e && e.target) e.target.disabled = false;
		},

		/**
		 * events - confirm
		 * 
		 * confirm close
		 */
		async closeConfirm(payload) {
			const confirm = this.confirm;
			const { e, value } = payload;
			const model = confirm.model;
			let result = null;
			let requestParams = null;

			if (value) {
				switch(confirm.type) {
					case "login":
						window.location.href=`/member/login?reurl=${location.pathname}${location.search}`;
					break;
					case "removeComment":
						result = await this.requestDeleteComment(this.getExistProperties({
							DP_DISPLAY_BOARD_ID: model.comment.DP_DISPLAY_BOARD_ID,
						}), model.uuid);
	
						if(1 === result) {
							requestParams = this.requests.components[model.uuid];
							requestParams.sPage = 1;
							this.requestComment(model.uuid);
						}
					break;
					default:
					break;
				}
			}


			confirm.isOpen = false;
		},

		/**
		 * events - pagination
		 * 
		 * 페이징 이동
		 */
		async movePage(payload) {
			const { e, id, page } = payload;
			const params = this.requests.components[id];
			const pagination = this.getPagination(id);
			let beforePage = null;
			let result = null;
			
			if(!pagination || !params) return;
			if(e && e.target) e.target.disabled = true;

			beforePage = pagination.current;
			pagination.current = page;
			params.sPage = 1 == page ? 1 : (page - 1) * pagination.rowCount + 1;
			params.ePage = page * pagination.rowCount;

			switch(pagination.type) {
				case "comment":
					result = await this.requestMoreComment(id);

					if(1 === result.code) {
						this.mergeComments({
							uuid: id,
							mergeComments: result.rows,
							total: result.total,
						});
					}
					else {
						pagination.current = beforePage;
						params.sPage = 1 == beforePage ? 1 : (beforePage - 1) * pagination.rowCount + 1;
						params.ePage = beforePage * pagination.rowCount;
					}
				break;
				default:
					this.fetches.components[id] = false;
					
					await this.requestPaginationComponent(id);
				break;
			}

			if(e && e.target) e.target.disabled = false;
		},

		/**
		 * events - comment
		 * 
		 * 댓글 및 답글 등록
		 */
		async submitComment(e, uuid) {
			const { comment, type } = e;
			const alert = this.alert;
			const verificationKey = [{
				key: ["comment"]
			}];
			let requestParams = null;
			let commentInput = null;
			let fileUploadParams = null;
			let commentPostParams = null;
			let addFile = [];
			let result = -1;

			if(comment) {
				commentInput = comment.input;

				if(this.verification(commentInput, verificationKey)) {
					fileUploadParams = {
						files: {
							ATTCHE_1: commentInput.files
						},
						data: {
							BIZ_CLASS: "UR",
							FOLDER1: "comment"
						}
					};
					//이 달력 보신 분 계세요?
					addFile = commentInput.files && commentInput.files.length ? await this.fileUpload(fileUploadParams.files, fileUploadParams.data) : [];
					commentPostParams = {
						DP_DISPLAY_ID: "reply" == type ? comment.DP_DISPLAY_ID : comment.id,
						COMMENT_TYPE: "reply" == type ? 2 : 1,
						UPPER_ID: "reply" == type ? comment.DP_DISPLAY_BOARD_ID : null,
						COMMENT: commentInput.comment,
						addFile: JSON.stringify(addFile)
					};
					result = await this.requestPostComment(this.getExistProperties(commentPostParams), uuid);

					if(1 === result) {
						requestParams = this.requests.components[uuid];

						if(requestParams) {
							commentInput.comment = null;
							commentInput.files = [];
							requestParams.sPage = 1;
							await this.requestComment(uuid);
						}
					}
					else {
						alert.message = result.RETURN_MSG || "오류가 발생하였습니다.";
						alert.isOpen = true;
					}
				}
				else {
					alert.message = "내용을 입력해주세요.";
					alert.isOpen = true;
				}
			}
			else {
				alert.message = "올바르지 않은 접근입니다.";
				alert.isOpen = true;
			}
		},

		/**
		 * events - comment
		 * 
		 * 댓글 및 답글 삭제
		 */
		async removeComment(e, uuid) {
			const confirm = this.confirm;
			const alert = this.alert;
			const { comment } = e;

			if(comment) {
				confirm.message = "댓글을 삭제하시겠습니까?";
				confirm.type = "removeComment";
				confirm.model = {
					uuid,
					comment
				}
				confirm.isOpen = true;
			}
			else {
				alert.message = "올바르지 않은 접근입니다.";
				alert.isOpen = true;
			}
		},

		/**
		 * events - video
		 * 
		 * video play
		 */
		async playVideo(e, uuid) {
			const alert = this.alert;
			const video = this.player[uuid];
			const el = e.target;

			if(!video) return;

			try {
				await video.play();
				el.classList.toggle("hide");
			}
			catch(ex) {
				switch(ex.name) {
					case "PasswordError":
						alert.message = "비밀번호가 필요한 영상입니다."
					break;
					case "PrivacyError":
						alert.message = "비공개 영상입니다."
					break;
					default:
						alert.message = "오류가 발생하였습니다."
					break;	
				}

				alert.isOpen = true;
			}
		},

		/**
		 * events - item
		 * 
		 * 상품 좋아요 버튼 이벤트
		 */
		async addWishGoods(e, item) {
			const alert = this.alert;
			const confirm = this.confirm;
			const login = this.login;
			let save = 0;

			if("Y" === login) {		
				this.disableEvent(e, true);
				save = await this.requestPutWishGoods(item);

				if ( 1 == save ) {
					if ( item.PRODUCT_FLAG === "T" ) {
						item.PRODUCT_FLAG = "F";
						// alert.isOpen = true;
						// alert.message = "관심 상품 목록에서 해제되었습니다";
						console.log("success");
					} else {
						item.PRODUCT_FLAG = "T";
						// this.itemConfirm.message = "관심 상품으로 등록되었습니다.<br/>관심 상품 목록으로 이동하시겠습니까?";
						// this.itemConfirm.isOpen = true;

						/*rtbhouse 광고 스크립트 추가 20240710*/
						(rtbhEvents = window.rtbhEvents || []).push(
							{
								eventType: 'wishlist',
								offerId: String(item.IL_ITEM_ID)
							},
							{
								eventType: 'uid',
								id: userId
							}
						);
					}
					// item.PRODUCT_FLAG = "T" === item.PRODUCT_FLAG ? "F" : "T";
				}
				else {
					alert.message = `[${save}] 오류가 발생하였습니다.`;
					alert.isOpen = true;
				}

				this.disableEvent(e, false);
			}
			else {
				confirm.message = "로그인이 필요합니다.<br/>로그인 하시겠습니까?";
				confirm.type = "login";
				confirm.isOpen = true;
			}
		},

		/**
		 * events - item
		 * 
		 * 브랜드 좋아요 버튼 이벤트
		 */
		async addWishBrand(e, item) {
			const alert = this.alert;
			const confirm = this.confirm;
			const login = this.login;
			let save = 0;

			if("Y" === login) {
				this.disableEvent(e, true);
				save = await this.requestPutWishBrand(item);

				if(1 == save) {
					if ( item.PRODUCT_FLAG === "T" ) {
						item.PRODUCT_FLAG = "F";
						alert.isOpen = true;
						alert.message = "관심 브랜드 목록에서 해제되었습니다";
					} else {
						item.PRODUCT_FLAG = "T";
						this.itemConfirm.message = "관심 브랜드로 등록되었습니다.<br/>관심 브랜드 목록으로 이동하시겠습니까?";
						this.itemConfirm.isOpen = true;
					}
				}
				else {
					alert.message = `[${save}] 오류가 발생하였습니다.`;
					alert.isOpen = true;
				}

				this.disableEvent(e, false);
			}
			else {
				confirm.message = "로그인이 필요합니다.<br/>로그인 하시겠습니까?";
				confirm.type = "login";
				confirm.isOpen = true;
			}
		},
	},
}

export default Layouts;