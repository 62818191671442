<template>
	<div>
		<h4 class="fb__title--hidden">댓글영역</h4>
		<div class="type-comments__total">   
			총 <em class="count">{{comment.total}}</em>개의 댓글 
			<!-- {{fileLength <= comment.input.files.length}} -->
		</div>
		<div class="comment__write">
			<div class="comment__write__input">
				<div class="comment__write__textarea">
					<textarea
						maxlength="300"
						:placeholder="commentPlaceholder"
						:readonly="'Y' !== login"
						tabindex="0"
						v-model="comment.input.comment"
						@click="checkMember($event)"
					>
					</textarea>
					<p class="comment__write__length">
						<em>{{comment.input.comment && comment.input.comment.length ? comment.input.comment.length : 0}}</em>/300
					</p>
				</div>
				<div class="comment__write__upload--wrapper">
					<label class="comment__write__upload--photo comment__write__upload--photo">
						<input
							class="blind"
							type="file"
							accept=".png, .jpg, .jpeg"
							tabindex="0"
							multiple
							:disabled="fileLength <= comment.input.files.length"
							@click="checkMember($event)"
							@change="importFiles('comment', $event, $event.target.files, 'image/png|image/jpeg', fileSize, fileLength - (comment.input.files ? comment.input.files.length : 0));"
						>
						<span>포토첨부</span>	
					</label>
					<div class="comment__write__upload--alert" v-show="comment.input.files && comment.input.files.length >= 3" @click="fileCountError()">3개이상 첨부파일 alert</div>
				</div>
				<button
					class="comment__write__upload--commit"
					@click="submitComment($event, 'comment', comment)"
				>등록</button>
			</div>

			<figure class="comment__write__imgWrap comment__image__list" v-if="comment.input.files">
				<span
					class="fb__image--1-1 comment__write__eachImg comment__image"
					v-for="(file, index) in comment.input.files"
					:key="index + '-comment-input-files'"
				>
					<img :src="file.src" alt="">
					<button class="comment__write__upload--del comment__write__upload--del" :disabled="disabledEvents" @click="removeFile($event, 'comment', file)">삭제</button>
				</span>
			</figure>
		</div>

		<template v-if="getCommentsCount()">
			<details
				class="comment"
				v-for="(comment, index) in comment.rows"
				:key="index + '-comment'"
			>
				<summary class="comment__fold">
					<div class="comment__info">
						<span class="comment__badge" v-if="'Y' == comment.SELF_YN">ME</span>
						<span class="comment__id">{{comment.LOGIN_ID}}</span>
						<span class="comment__date">{{stringDateToDate(comment.CREATED, 'YYYY.MM.DD')}}</span>

						<div class="comment__btns">
							<span ref="commentIcon" class="comment__btns--re" v-if="!comment.DEL_FLG">
								<span>댓글아이콘</span>
								<em>{{comment.replies && comment.replies.length ? comment.replies.length : 0}}</em>
							</span>
							<button
								class="comment__btns--del"
								:disabled="disabledEvents"
								tabindex="0"
								v-if="('Y' == comment.SELF_YN && !comment.DEL_FLG)"
								@click="removeComment($event, 'comment', comment)"
							>삭제버튼</button>
						</div>
					</div>

					<div class="comment__cont">
						<p class="comment__text" v-html="1 == comment.DEL_FLG ? '작성자에 의해 삭제된 댓글입니다.' : 2 == comment.DEL_FLG ? '관리자에 의해 삭제된 댓글입니다.' : comment.COMMENT">
							<!-- TODO: DEL_FLG 임시처리... -->
						</p>
						<div class="comment__write__imgWrap"> 
							<figure
								class="fb__image--1-1 comment__write__eachImg"  
								tabindex="0"
								v-for="(file, index2) in comment.attac"
								:key="index + '-' + index2 + '-image'"
								@click.prevent="clickImage($event, 'image', comment.attac, (index2 + 1))" 
							>  
								<img v-lazy="imageRoot + '/' + file.REAL_ATTACHED" alt="" :key="imageRoot + '/' + file.REAL_ATTACHED">
							</figure>
						</div>
					</div>
				</summary> 

				<!-- 대댓글영역 -->
				<div class="comment__open" :class="'Y' != login ? 'nologin' : ''" v-if="!comment.DEL_FLG">
					<ul v-if="comment.replies">
						<li v-for="(reply, index2) in comment.replies" :key="index + '-' + index2 + '-reply'">
							<div class="comment__info">
								<span class="comment__badge" v-if="'Y' == reply.SELF_YN">ME</span>
								<span class="comment__id">{{reply.LOGIN_ID}}</span>
								<span class="comment__date">{{stringDateToDate(reply.CREATED, 'YYYY.MM.DD')}}</span>
								<div class="comment__btns">
									<button class="comment__btns--del" :disabled="disabledEvents" v-if="'Y' == reply.SELF_YN && !reply.DEL_FLG" tabindex="0" @click="removeComment($event, 'reply', reply)">삭제버튼</button>
								</div>
							</div>
							<div class="comment__cont">
								<p class="comment__text" v-html="1 == reply.DEL_FLG ? '삭제된 댓글입니다.' : reply.COMMENT">
									<!-- TODO: DEL_FLG 임시처리... -->
								</p>
							</div>
						</li>
					</ul>
					
					<div class="comment__write__input" v-if="'Y' == login">
						<div class="comment__write__textarea">
							<textarea
								cols="30"
								rows="10"
								maxlength="300"
								:placeholder="commentPlaceholder"
								:readonly="'Y' !== login" tabindex="0"
								v-model="comment.input.comment"
								@click="checkMember($event)"
							></textarea>
							<p class="comment__write__length">
								<em>{{comment.input.comment && comment.input.comment.length ? comment.input.comment.length : 0}}</em>/ 300
							</p>
						</div>
						<button
							class="comment__write__upload--commit"
							@click="submitComment($event, 'reply', comment)"
						>등록</button>
					</div>
				</div>
				<!-- //대댓글영역 -->
			</details>
		</template>
	</div>
</template>
<script>
	import mixin from "../../mixins/mixin";
	import files from "../../mixins/files";

    export default {
		name : "comments-component",
		mixins: [mixin, files],

		props: {
			/**
			 * type: {
			 *		id: String,
			 *		input: {
			 *			comment: any,
			 *			files: Array
			 *		},
			 *		rows: Array,
			 *		total: Number | String
			 *	}
			 */
			comment: {
				type: Object,
				required: true,
				validator(value) {
					const has = Object.prototype.hasOwnProperty;
					const id = value.id;
					const input = value.input;

					return id
						&& has.call(value, "input")
						&& has.call(input, "comment")
						&& input.files instanceof Array
						&& (
							null === value.rows
							|| value.rows instanceof Array
						)
						&& (
							0 === value.total
							|| "number" === typeof value.total
							|| "string" === typeof value.total
						);
				}
			},

			fileLength: {
				type: Number,
				default: 3,
			},

			fileSize: {
				type: Number,
				default: 30,
			},

			login: {
				type: String,
				required: true,
			},

			imageRoot: {
				type: String,
				required: true,
			},

			disabledEvents: {
				type: Boolean,
				default: false,
			}
		},

		watch: {
			"comment.rows": {
				handler(newValue, oldValue) {
					this.initCommentInput();
				},
			},
			deep: true
		},

		computed: {
			commentPlaceholder() {
				return "Y" == this.login ? "저작권에 위배되며 타인에 대한 욕설과 비방이 담긴 댓글의 경우 삭제 될 수 있습니다." : "로그인하시면 댓글을 남길 수 있어요.";
			},
		},

		created() {
			this.initCommentInput();
		},

		methods: {
			/**
			 * init
			 * 
			 * comment object에 입력 prop 생성
			 */
			initCommentInput() {
				const rowCount = this.getCommentsCount();
				let row = null;

				if(rowCount) {
					for(let i = 0, maxCnt = rowCount; i < maxCnt; i++) {
						row = this.comment.rows[i];

						this.$set(row, "input", {
							comment: null
						});
					}
				}
			},

			/**
			 * getter
			 * 
			 * 댓글 갯수조회
			 * 
			 * @return {Number} 댓글 갯수
			 */
			getCommentsCount() {
				const comment = this.comment;
				return comment.rows && comment.rows.length ? comment.rows.length : 0;
			},

			/**
			 * get
			 * 
			 * 댓글 XSS 공격 방지
			 * 
			 * @return {String} 댓글 텍스트
			 */
			getXSSFiltering(text) {
				return window.filterXSS(text, { stripIgnoreTag : true });
			},

			/**
			 * mixin - files
			 */
			async completedFileImport(id, element, file) {
				file.src = await this.readAsDataURL(file);
				this.comment.input.files.push(file);
			},

			/**
			 * mixin - files
			 */
			completedFilesImport(id, element, file) {
				element.value = "";
			},

			/**
			 * mixin - files
			 */
			fileCountError(id, element) {
				this.$emit("warn", {
					message: "최대 3개의 파일만 업로드 가능합니다."
				});
			},

			fileSizeError(id, element, file) {
				this.$emit("size-error", {
					message: "사진은 최대 30MB까지 등록 가능합니다."
				})
			},

			fileFormatError(id, element, file) {
				const alert = this.alert;

				this.$emit("format-error", {
					message: "파일은 jpg, jpeg, png만 등록 가능합니다."
				});

			},

			/**
			 * mixin - files
			 */
			removeFile(e, id, file) {
				const files = this.comment.input.files;

				files.splice(files.indexOf(file), 1);
			},

			/**
			 * events
			 * 
			 * 부모에게 댓글 및 답글등록 이벤트 전파
			 */
			submitComment(e, type, comment) {
				if("Y" === this.login) {
					comment.input.comment = this.getXSSFiltering(comment.input.comment);
					comment.input.comment = comment.input.comment ? comment.input.comment.replace(/(\n|\r\n)/g, '<br>') : "";
					
					this.$emit("submit-comment", {
						e,
						type,
						comment
					});
				}
				else {
					this.checkMember(e);
				}
			},

			/**
			 * events
			 * 
			 * 부모에게 댓글 삭제 이벤트 전파
			 */
			removeComment(e, type, comment) {
				if("Y" === this.login) {
					this.$emit("remove-comment", {
						e,
						type,
						comment
					});
				}
				else {
					this.checkMember(e);
				}
			},

			/**
			 * events
			 * 
			 * 로그인 필요 이벤트 전파
			 */
			checkMember(e) {
				if("Y" != this.login) {
					e.preventDefault();

					this.$emit("do-login", {
						e
					});
				}
			},

			/**
			 * events
			 * 
			 * 부모에게 임지ㅣ 클릭 이벤트 전파
			 */
			clickImage(e, type, image, num) {
				this.$emit("click-image", {
					e,
					type,
					image,
					num
				});
			},
		}
    }
</script>
<style scoped lang="scss">
	.type {
		&-comments {
			
			&__total {
				display: block;
				margin-bottom: 2.66666vw;
				text-align: left;
				font-size: 3.46666vw;
				color: #787878;
				letter-spacing: -0.01rem;

				em {
					color: #ff3e2f;
				}
			}

			.comment {
				&__write {
					&__input {
						position: relative;
						margin-bottom: 5.33333vw;
						padding-bottom: 10.4vw;
						border: 0.26666vw solid #e5e5e5;
					}

					&__textarea {
						height: 23.06666vw;

						textarea {
							width: 100%;
							height: 100%;
							padding: 4vw 3.33333vw;
							line-height: 5.6vw;
							font-size: 3.73333vw;
							color: #000;
							box-sizing: border-box;

							&::placeholder {
								color: #b2b2b2;
							}
						}
					}

					&__length {
						position: absolute;
						bottom: 3.06666vw;
						left: 3.33333vw;
						color: #787878;
						font: {
							family: 'GillSansWGL', sans-serif;
							size: 3.46666vw;
						}
					}

					&__upload {
						&--wrapper {
							position: absolute;
							bottom: -0.26666vw;
							right: 17.86666vw;
							width: 15.73333vw;
							height: 10.66666vw;
							font-size: 0;
						}

						&--alert {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							font-size: 0;
							opacity: 0;
						}

						&--photo {
							display: block;
							width: 15.73333vw;
							height: 10.66666vw;
							background:transparent url(/mobile/assets/images/common/ico_comm_photo.png) no-repeat 50% 50% / 5.06666vw 4.53333vw;
							font-size: 0;
						}

						&--commit {
							position: absolute;
							bottom: -0.26666vw;
							right: -0.26666vw;
							width: 18.13333vw;
							height: 10.66666vw;
							background: #000;
							border: 0.26666vw solid #000;
							color: #fff;
							font-size: 3.73333vw;
							box-sizing: border-box;
						}

						&--del {
							position: absolute;
							top: 0;
							right: 0;
							width: 5.33333vw;
							height: 5.33333vw;
							background: rgba(0, 0, 0, 0.8) url(/mobile/assets/images/common/ico_comm_close-white.png) no-repeat 50% 50%;
							font-size: 0;
						}
					}

					&__imgWrap {
						display: block;
						padding-bottom: 5.33333vw;
						border-bottom: 0.26666vw solid #e5e5e5;
						&::after {
							display: block;
							clear: both;
							content: "";
						}

						&:empty {
							display: none;
						}
					}

					&__eachImg {
						overflow: hidden;
						position: relative;
						float: left;
						width: 24vw;
						height: 24vw;
						margin-right: 2.66666vw;
						box-sizing: border-box;
						background: #f7f7f7;

						img {
							position: absolute;
							top: 50%;
							left: 50%;
							width: 100%;
							height: auto;
							max-height: none;
							transform: translate(-50%, -50%);
						}
					}
				}

				&__list {
					margin-top: 16vw;
				}

				&__fold {
					padding: 4vw 0 6vw;
					border-bottom: 0.26666vw solid #e5e5e5;
				}

				&__open {
					padding: 4.66666vw 2.66666vw;
					background: #f7f7f7;
					border-bottom: 0.26666vw solid #e5e5e5;

					&.nologin {
						padding: 0;
						border-bottom: 0;

						ul {
							padding: 4.66666vw 2.66666vw;
						}
					}

					* {
						color: #787878 !important;
					}

					ul {
						padding: 0 2.66666vw;

						li {
							margin-bottom: 6.66666vw;

							&:last-of-type {
								margin-bottom: 0;
							}
						}


						& + .comment__write__input {
							margin-top: 6.66666vw;
						}
					}

					.comment__write {
						&__input {
							margin-bottom: 0;
							background: #FFF;
						}
						
						&__textarea {
							color: #000 !important;

							&::placeholder {
								color: #b2b2b2 !important;
							}
						}

						&__upload--commit {
							color: #fff !important;
						}
					}
				}

				&__info {
					position: relative;
					font-size: 0;
				}

				&__badge {
					display: inline-block;
					height: 4.26666vw;
					padding: 0 0.13333;
					border: 0.26666vw solid #787878;
					vertical-align: middle;
					font: {
						size: 3.46666vw;
						weight: bold;
						family: "Archivo Black";
					};
					color: #787878;
					line-height: 3.73333vw;
					box-sizing: border-box;
				}

				&__id {
					display: inline-block;
					position: relative;
					margin-left: 1.33333vw;
					padding-right: 3.06666vw;
					vertical-align: middle;
					color: #000;
					min-height: 3.066667vw;
					font: {
						size: 3.73333vw;
						family: "Archivo Black";
					};

					&:before {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						width: 0.26666vw;
						height: 4.26666vw;
						background: #e5e5e5;
					}
				}

				&__date {
					display: inline-block;
					margin-left: 2.66666vw;
					vertical-align: middle;
					font: {
						family: 'GillSansWGL', sans-serif;
						size: 3.46666vw;
					};
					color: #787878;
				}

				&__btns {
					position: absolute;
					top: 0;
					right: 0;
					&::after {
						display: block;
						clear: both;
						content: "";
					}

					button {
						display: inline-block;
						vertical-align: middle;
					}

					.comment__btns--re {
						display: inline-block;
						vertical-align: middle;
					}

					&--re {
						&::after {
							display: block;
							clear: both;
							content: "";
						}
					
						span {
							float: left;
							width: 4.53333vw;
							height: 4.4vw;
							background: url(/mobile/assets/images/common/ico_comm_re.png) no-repeat 0 0 / 9.33333vw 4.4vw;
							vertical-align: middle;
							font-size: 0;
						}

						em {
							float: left;
							margin-left: 2vw;
							font: {
								family: 'GillSansWGL', sans-serif;
								size: 4vw;
							};
							color: #787878;
							line-height: 4.26666vw;
						}

						&.active {
							span {
								background-position: -4.8vw 0;
							}

							em { 
								font-weight: bold;
								color: #000;
							}
						}
					}

					&--del {
						width: 3.6vw;
						height: 3.46666vw;
						margin-left: 4vw;
						background: url(/mobile/assets/images/common/ico_comm_re-close.png) no-repeat 0 0 / 3.6vw 3.46666vw;
						font-size: 0;
					}
				}

				&__cont {
					position: relative;
					margin-top: 2vw;

					.comment__write__imgWrap {
						margin-top: 4vw;
						padding-bottom: 0;
						border-bottom: 0;
					}
				}

				&__text {
					display: block;
					font-size: 4vw;
					line-height: 6.13333vw;
					letter-spacing: -0.01rem;
					word-break: break-all;
				}
			}
		}
	}
</style>