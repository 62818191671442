<template>
    <section class="fixed top-0 left-0 w-full h-full flex flex-no-wrap flex-col items-center z-50" :class="{'noDim' : !isBackground}" @click.self="isBackgroundClose ? close() : focusCloseButton($event)">
		<div class="alert__wrapper p-3 m-auto bg-white popup__alert new_mo" :style="`${width ? 'width: ' + width + ';' : ''}${height ? 'height: ' + height + ';' : ''}`">
			<div class="layer__header">
                    <a href="javascript:;" class="layer__close" @click="close($event, false)">닫기</a>
			</div>
			<div class="alert__content text-center" v-html="message || ''"></div>
			<footer class="text-right" v-if="isCloseButton">
				<button class="p-2" ref="close-button" v-focus @keydown.enter="close($event)" @click="close($event)">{{ isConfirmButton ? '확인' : '닫기' }}</button>
			</footer>
		</div>
	</section>
</template>
<script>
    export default {
        name : "alert-component",

		props: {
			isBackgroundClose: {
				type: Boolean,
				default: false
			},

			isBackground: {
				type: Boolean,
				default: true
			},

			isCloseButton: {
				type: Boolean,
				default: true
			},

			width: {
				type: String | Number,
				default: null
			},

			height: {
				type: String | Number,
				default: null
			},

			message: {
				type: String,
				default: ""
			},
			isConfirmButton: { //확인 버튼 표시 여부
				type: Boolean,
				default: false
        	},
		},
		
		mounted() {
			document.body.style.width = document.body.scrollWidth + "px";
			document.body.classList.add("scroll-lock");
		},

		beforeDestroy() {
			document.body.style.width = null;
			document.body.classList.remove("scroll-lock");
		},
		
        methods: {
			focusCloseButton(e) {
				this.$refs["close-button"].focus();
			},

			close(e) {
				this.$emit("close-alert", {
					e
				});
			},
		},

		directives: {
			focus: {
				// 디렉티브 정의
				inserted: function (el, binding, vnode) {
					el.focus();
				}
			}
		},
    }
</script>
<style scoped lang="scss">
	section {
		$max-width: 80vw;
		$min-width: 40vw;
		background: rgba(0, 0, 0, .5);

		&.noDim {
			background: none;
		}

		.alert__wrapper {
			max-width: $max-width;
			min-width: $min-width;
			padding: 0;
			box-shadow: 0 0.266vw 0.533vw -1px rgba(0, 0, 0, 0.2), 0 0.533vw 0.666vw rgba(0, 0, 0, 0.14), 0 1px 1.333vw rgba(0, 0, 0, 0.12);
			width:89.333vw;
			position: fixed;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		.alert__content {
			line-height: 5.066vw;
			font-size: 4vw;
			word-break: keep-all;
    		word-wrap: break-word;
			padding:2.667vw 13vw 0;
		}

		footer {
			margin-top: 10.667vw;
			text-align: center;
			font-size: 0;
			width: 100%;

			button {
				width: 100%;
				height: 16vw;
				padding: 0;
				line-height: 5.066vw;
				font-size: 4vw;
				color: #fff;
				background-color: #000;
			}
		}
	}
</style>