/*
	다이퀘스트 필터
*/
const mixinControllerDFilters = {
	data() {
		return {
			has: Object.prototype.hasOwnProperty,
			
			fetches: {
				filterGroups: false,
			},

			requests: {
				filterGroups: {
					IL_CTGRY_ID: null,
					IL_BRAND_ID: null,
					SEARCH_WORD: null,
				},
			},

			filterType: null,
			filterGroupParamKey: "IL_CTGRY_ID",
			filterNextKey: "goods",
			filterOptions: {
				isReload: true,
				isHistoryURL: true,
			},
			filterCategories: null,
			filterSubCategories: {},
			filterSelectedCategories: {
				first: null,
				second: null,
				third: null,
			},
			filterCategoryDepths: ["first", "second", "third"],

			//
			filterPrice: { key: "pr", type: "price" },
			filterCategory: { key: "ct", type: "category" },
			filterFilter: { key: "CL" },
			filterBrand: { key: "b", type: "search" },
			filterGroups: {},
			filterSearch: {},
			filterElements: {
				range: {
					noUiSlider: null,
					min: 0,
					max: 0
				},
			},
			filterFree : {key:"1-2001", type : "condition"},

			isFilterCategoryActive1: null,
			isFilterCategoryActive2: null,
			isFilterCategoryActive3: null,
			filterCategoryViewConut: 5,
			filtersApplied: [],

			// mobile only
			filtersModal: {
				selected: null,
			},
		}
	},

	watch: {
		filtersApplied: {
			handler(newValue, oldValue) {
				// if(!this.isLoaded || (!newValue.length && 1 < oldValue.length)) return;
				
				const method = newValue.length > oldValue.length ? "add" : "delete";
				const filters = _.difference("add" == method ? newValue : oldValue, "add" == method ? oldValue : newValue);

				$(".filterCnt").text('');
				$(".filter_name li").removeClass("f_checked");
				$("#filterKind button").removeClass("btn_clicked");
				$(".filterNm[group_id=pr]").text("가격"); 
				$(".is-price input[name=price]").prop("checked", false);
				this.filterElements.range = []; 
				
				//선택한 필터 검정색, 중복선택가능 일때는 카운트, 중복선택불가일때는(filterApplyPrice) 필터값 추가
				var conditionType = "";
				this.filtersApplied.map(function(val, idx){
					if(val.ID == "1-2001"){ //무료배송
						conditionType = "1";
					}
				});
				this.requests.goods.CONDITION_TYPE = conditionType;
				
				//외부
				this.$nextTick(() => {
					var filtersApplied = this.filtersApplied,
						conditionType = "";
					$("#filterKind button").each(function(idx,val){
						var filter = $(this), cnt = 0, selTitle="", disTitle = filter.text().trim();
						filtersApplied.map(function(val, idx){
							selTitle = (val.GROUP_ID == "b" || val.GROUP_ID == "pr") ? val.TITLE : val.GROUP_ID.replace("Result","");
							if( selTitle == disTitle){
								cnt++;
								filter.addClass("btn_clicked");
								filter.find(".filterCnt").text(cnt);
								if(val.GROUP_ID == "pr"){ //가격
									filter.find(".filterNm").text(val.NAME);
									filter.find(".filterCnt").text('');
								}
								if(val.ID == "1-2001"){ //무료배송
									filter.find(".filterCnt").text('');
								}
							}
						});
					});

					//내부
					$(".filter_name li").each(function(idx,val){
						var disTitle = $(val).find(".filterNm").text().trim(), filter = $(this), selTitle="";
						filtersApplied.map(function(val, idx){
							selTitle = (val.GROUP_ID == "b" || val.GROUP_ID == "pr") ? val.TITLE : val.GROUP_ID.replace("Result","");
							if( selTitle == disTitle){
								filter.addClass("f_checked");

								if(val.selId ){
									$("#" + val.selId).prop("checked", true);
								}else if(val.isDirect){
									$("#price5").prop("checked", true);
								}
							}
						});
					});
				});



				if(filters && filters.length) {
					if(this.filterOptions.isHistoryURL) {
						for(let i = 0, maxCnt = filters.length; i < maxCnt; i++) {
							let filter = filters[i];

							if(i + 1 == maxCnt) this.filterSetHistory(this.filterModifyUrlSearch([method], true, [filter.ID], [filter.NAME]));
							else this.filterModifyUrlSearch([method], true, [filter.ID], [filter.NAME]);
						}
					}

					this.resetPagination(this.filterNextKey);
					this.reload(null, this.filterNextKey);					
				}
			},
		}
	},

	methods: {
		async requestFilters() {
			try {
				let filters = [];
				let filterGroups = null;
				let others = null;
				let categories = null;
				let brands = null;		
				let bestBrands = null;						
				let price = null;
				let sortFilterGroups  = {};
				let noFilterGroups = {};
				
				const parameters = this.requests.filterGroups;
				const response = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/search/filter",
					data: this.filterMakeRequestData()
				});
				const rows = response.filterObj;

				if(0 == rows.rs || 1 == rows.rs) {
					others = this.filterMakeOtherFilters(this.filterGetOtherFilters(rows));
					categories = this.filterMakeCategoryFilters(this.filterGetCategoryFilters(rows));
					brands = this.filterMakeBrandFilters(this.filterGetBrandFilters(rows));
					bestBrands = this.filterMakeBestBrandFilters(this.filterGetBrandFilters(rows));
					price = this.filterMakeRangePriceFilters(this.filterGetRangePriceFilters(rows));
					
					filters = filters.concat(categories || [], others || [], brands || [], bestBrands || [], price || []);
					filterGroups = filters && filters.length ? _.groupBy(filters, "GROUP_ID") : null;
					
					if(filterGroups){
						$.each(filterGroups,function(idx,val){
							filterGroups[idx] = _.orderBy(filterGroups[idx], [item => parseInt(item.SORT)], ['asc']);
						});
					}

					if(filterGroups && filterGroups.b) { //브랜드
						filterGroups.b = _.orderBy(filterGroups.b, [item => parseInt(item.groupCnt)], ['desc']);
					}

					if(price && price.length) {
						this.setPricePreset(price);
						this.filterInitRange(price[0].MIN, price[0].MAX);
						this.filterInitActiveCategory();
					}
					
					if(rows.attrIdArr){
						$.each(rows.attrIdArr, function(idx,val){
							$.each(filterGroups, function(idx2,val2){
								if(val.NAME == val2[0].TITLE.replace("Result", "")){
									if(val.filterGroups ==undefined){val.filterGroups = []}
									val.filterGroups.push(val2)
								}
							});
						});
	
						rows.attrIdArr.map(function(val, idx){
							if(sortFilterGroups[val.NAME] == undefined){sortFilterGroups[val.NAME] = [];}
							sortFilterGroups[val.NAME].push(val.filterGroups[0]);
						});
	
						if(sortFilterGroups["b"] == undefined){sortFilterGroups["b"] = [];}
						sortFilterGroups["b"].push(filterGroups.b);
	
						if(sortFilterGroups["pr"] == undefined){sortFilterGroups["pr"] = [];}
						sortFilterGroups["pr"].push(filterGroups.pr);
	
						//this.filterGroups = sortFilterGroups;
						filterGroups = sortFilterGroups;
					}else{//매핑된 필터가 없을 때
						if(filterGroups){
							$.each(filterGroups, function(idx, val){
								if(noFilterGroups[idx] == undefined){noFilterGroups[idx] = [];}
								noFilterGroups[idx].push(val);
							});
							filterGroups = noFilterGroups;
						}
					}

					
					// if(filterGroups && filterGroups.b) filterGroups.b.push(_.groupBy(filterGroups.b, "CHAR_CODE"));
					
					this.filtersApply(filters);
					this.$set(this.filterGroups, parameters[this.filterGroupParamKey] || this.filterType, filterGroups);
					this.filtersModal.selected = filterGroups ? filterGroups[Object.keys(filterGroups)[0]] : null;
					this.fetches.filterGroups = true;
					
				}
				else {
					console.error("requestFilters fail...", rows.rs);

					this.fetches.filterGroups = "error";
				}
			}
			catch(ex) {
				console.error("requestFilters exception...", ex);

				this.fetches.filterGroups = "error";
			}
		},

		setPricePreset(price){
			var presetArr = [], min = Number(price[0].MIN), max = Number(price[0].MAX);
			presetArr[0] = { ...price[0] };
			
			for(var i = 1; i<5; i++){
				presetArr.push({ ...price[0] });
				if(i==1){
					presetArr[i].MIN = Math.round(min/100) * 100;
					presetArr[i].MAX = Math.round((min + (max - min) * 0.25)/100) * 100;
				}
				if(i==2){
					presetArr[i].MIN = Math.round((min + (max - min) * 0.25)/100) * 100 + 1;
					presetArr[i].MAX = Math.round((min + (max - min) * 0.5)/100) * 100;
				}
				if(i==3){
					presetArr[i].MIN = Math.round((min + (max - min) * 0.5)/100) * 100 + 1;
					presetArr[i].MAX = Math.round((min + (max - min) * 0.75)/100) * 100;
				}
				if(i==4){
					presetArr[i].MIN = Math.round((min + (max - min) * 0.75)/100) * 100 + 1;
					presetArr[i].MAX = Math.round(max/100) * 100;
				}
				presetArr[i].NAME = presetArr[i].MIN + "원 ~ " + presetArr[i].MAX + "원";
			}

			this.filterElements.preset = presetArr;
			this.filterElements.preset[0].MIN = numberWithCommas(this.filterElements.preset[0].MIN);
			this.filterElements.preset[0].MAX = numberWithCommas(this.filterElements.preset[0].MAX);
		},

		// get
		filterGetRangePriceKeys() {
			return ["startPrice", "endPrice"];
		},

		filterGetRangePriceFilters(diquestFilters) {
			let filteringKeys = this.filterGetRangePriceKeys();
			const priceFilters = {};

			for(let [key, value] of Object.entries(diquestFilters)) {
				priceFilters[key] = value;
			}

			return priceFilters;
		},

		filterMakeRangePriceFilters(diquestFilters) {
			let filteringKeys = this.filterGetRangePriceKeys();
			let priceFilters = null;

			if ( diquestFilters[filteringKeys[0]] && diquestFilters[filteringKeys[1]]  ) {
				priceFilters = [{
					DIS_TYPE: this.filterPrice.type,
					GROUP_ID: this.filterPrice.key,
					ID: this.filterPrice.key,
					TITLE: "가격",
					NAME: "가격",
					MIN: diquestFilters ? (diquestFilters[filteringKeys[0]]) || 0 : 0,
					MAX: diquestFilters ? (diquestFilters[filteringKeys[1]]) || 999999999 : 999999999
				}];
			}

			return priceFilters;
		},

		filterGetRangePrice(diquestFilters) {
			if(!diquestFilters) return null;
			
			const result = null !== diquestFilters.startPrice && undefined !== diquestFilters.startPrice && null !== diquestFilters.endPrice && undefined !== diquestFilters.endPrice
						? {
							min: diquestFilters.startPrice,
							max: diquestFilters.endPrice,
						}
						: null;

			return result;
		},

		filterGetOtherFilters(diquestFilters) {
			let filteringKeys = [];
			const otherFilters = {};
			filteringKeys = filteringKeys.concat(this.filterGetCategoryKeys(), this.filterGetBrandKeys(), this.filterGetRangePriceKeys());

			for(let [key, value] of Object.entries(diquestFilters)) {
				if(!filteringKeys.includes(key)) {
					otherFilters[key] = value;
				}
			}

			return otherFilters;
		},

		filterGetSearchTypeResult(filters) {
			const searchFilters = _.cloneDeep(filters);
			const text = this.filterSearch[searchFilters[0].GROUP_ID];

			// searchFilters.splice(searchFilters.length - 1, 1);

			return text ? searchFilters.filter(v => -1 != v.NAME.indexOf(text)) : searchFilters;
		},

		filterGetBrandKeys() {
			return ["brandResult"];
		},

		filterGetBrandFilters(diquestFilters) {
			return {
				brandResult: diquestFilters.brandResult,
				bestBrandResult: diquestFilters.bestBrandResult
			} 
		},

		filterGetCategoryKeys() {
			return ["lcateResult", "mcateResult", "scateResult", "dcateResult"];
		},

		filterGetCategoryFilters(diquestFilters) {
			return {
				lcateResult: diquestFilters.lcateResult,
				mcateResult: diquestFilters.mcateResult,
				scateResult: diquestFilters.scateResult,
				dcateResult: diquestFilters.dcateResult
			} 
		},

		filterGetCategoryChildren(parentGroupNm, viewCount) {
			const currentFilterGroupKey = this.requests.filterGroups[this.filterGroupParamKey] || this.filterType;
			const categories = this.filterGroups[currentFilterGroupKey][this.filterCategory.key];
			const findCategories = categories.filter(v => v.PARENT == parentGroupNm);

			if(findCategories && findCategories.length) {
				if(viewCount) return findCategories.slice(0, viewCount);
				else return categories.filter(v => v.PARENT == parentGroupNm);
			}
			else {
				return [];
			}
		},
		
		filterGetCategoryId(keyName) {
			if(!keyName) return;
					
			const value = keyName.split("||");

			return value && 3 === value.length ? value[0] : "";
		},

		filterGetCategoryDepth(keyName) {
			if(!keyName) return;
					
			const value = keyName.split("||");

			return value && 3 === value.length ? value[1] : "";
		},

		filterGetCategoryName(keyName) {
			if(!keyName) return;
			
			const value = keyName.split("||");

			return value && 3 === value.length ? value[2] : "";
		},

		// set
		filterSetSelectedCategory(categories, code, depth, name) {
			let category = categories.find(v => v.GROUP_ID === this.filterCategory.key && v.ID == code);
			let categoryDepth = Number.isInteger(depth) ? this.filterCategoryDepths[depth] : depth;
			let parentCategory = null;
			let refs = null;

			if(!category) return;

			switch(categoryDepth) {
				case "first":
					this.filterSelectedCategories.first = `${code}||${depth}||${name}`;
					refs = this.$refs[this.filterSelectedCategories.first];
				break;
				case "second":
					this.filterSelectedCategories.second = `${code}||${depth}||${name}`;
				break;
				case "third":
					this.filterSelectedCategories.third = `${code}||${depth}||${name}`;
				break;
			}

			parentCategory = categories.find(v => v.GROUP_ID === this.filterCategory.key && category.PARENT == v.ORIGINAL_NAME);
			if(refs && refs.length) refs[0].setAttribute("open", "");
			if(category.PARENT && parentCategory) {
				this.filterSetSelectedCategory(categories, parentCategory.ID, depth - 1, parentCategory.NAME);
			}
		},

		filterSetHistory(queryString) {
			this.setHistory(null, true, queryString ? `?${queryString.toString()}` : "?")
		},

		// init
		filterInitSearch(filters) {
			const searchTypes = _.uniqBy(filters.filter(v => "search" == v.DIS_TYPE), "GROUP_ID");

			for(let i = 0, maxCnt = searchTypes.length; i < maxCnt; i++) {
				this.$set(this.filterSearch, searchTypes[i].GROUP_ID, null);
			}
		},

		filterInitRange(min, max) {
			const range = this.filterElements.range;
			const rangeElement = this.$refs["price-range"];

			if(rangeElement) {
				if(range.noUiSlider) {
					range.noUiSlider.set([min, max]);
				}
				else {
					range.noUiSlider = window.noUiSlider.create(rangeElement, {
						start: [min || 0, max || 99999999],
						connect: true,
						range: {
							min: parseInt(min) || 0,
							max: parseInt(max) || 99999999
						},
						format: window.wNumb({
							decimals: 0,
							thousand: ',',
							to(value) {
								return value + ',-';
							},
							from(value) {
								return Number(value.replace(',-', ''));
							}
						})
					});

					range.noUiSlider.on('update', function(values) {
						range.min = values[0];
						range.max = values[1];
					});
				}
			}
		},

		filterInitSelectedCategory(categories, value) {
			let categorySplit = null;

			categorySplit = decodeURIComponent(value).split("||");

			if(categorySplit && 3 === categorySplit.length) {
				this.filterSetSelectedCategory(categories, categorySplit[0], categorySplit[1], categorySplit[2]);
			}
		},

		filterInitActiveCategory() {
			const filterGroupsParams = this.requests.filterGroups;
			let firstCategory = this.filterSelectedCategories ? this.filterSelectedCategories.first : null;
			let categorySplit = null;

			if(!firstCategory) return;

			categorySplit = firstCategory.split("||");

			if(categorySplit && 3 === categorySplit.length) {
				const categories = this.filterGroups[filterGroupsParams.IL_CTGRY_ID || filterGroupsParams.IL_BRAND_ID || this.filterType][this.filterCategory.key];
				const findFirstCategories = categories.filter(v => v.PARENT === null);
				const categoryIndex = findFirstCategories.findIndex(v => v.ORIGINAL_NAME === `${categorySplit[0]}_${categorySplit[2]}`);

				if(-1 !== categoryIndex) this.filterToggleCategoryDetails(categoryIndex);
			}
		},

		// make
		filterMakeUrl() {
			const params = this.requests.filterGroups;
			let url = [`/search/filter?searchPage=${this.filterType}`];

			switch(this.filterType) {
				case "search":
				case "brandSearch":
					if(params.SEARCH_WORD) url.push(`searchTerm=${params.SEARCH_WORD}`);
				break;
				case "brand":
					if(params.IL_BRAND_ID) url.push(`searchBrand=${params.IL_BRAND_ID}`);
				break;
				case "cate":
					url.push(`searchCate=${params.IL_CTGRY_ID}`);
				break;
			}

			return url.join("&");
		},

		filterMakeRequestData() {
			const params = this.requests.filterGroups;
			const data = {
				searchPage: this.filterType
				, searchMainType:mainType
			}

			switch(this.filterType) {
				case "search":
				case "brandSearch":
					data.searchTerm = params.SEARCH_WORD || "";
				break;
				case "brand":
					data.searchBrand = params.IL_BRAND_ID || "";
				break;
				case "cate":
					data.searchCate = params.IL_CTGRY_ID;
				break;
			}

			return data;
		},

		filterMakeOtherFilters(diquestFilters) {
			if(!diquestFilters) return [];

			let otherFilters = _.cloneDeep(diquestFilters);
			let otherFilter = null;
			let nameSplit = null;
			let name = null;
			let color = null;
			let result = [];

			for(let [key, value] of Object.entries(otherFilters)) {
				otherFilter = otherFilters[key];

				if(otherFilter && otherFilter.length) {
					if(!(key == "bestBrandResult" || key == "bestBrandResult"|| key == "attrIdArr")) {
						result = result.concat(otherFilters[key].map(v => {
							nameSplit = v.groupNm.split("||");
							name = nameSplit[3];
							color = nameSplit[4];
							v.GROUP_ID = key;
							v.TITLE = key;
							v.ID = `${nameSplit[0]}-${nameSplit[2]}`;
							v.NAME = name;
							v.ORIGINAL_NAME = v.groupNm;
							v.COLOR = color;
							v.DIS_TYPE = color ? "RC" : "CL";
							v.SORT = nameSplit[0];

							return v;
						}));
					}
				}
				else {
					continue;
				}
			}

			return result;
		},

		filterMakeBrandFilters(diquestFilters) {
			if(!diquestFilters) return [];

			let filters = [];
			let originalCharCode = null;
			let charCode = null;
			let brandNameSplit = null;
			let brandName = null;

			if(diquestFilters.brandResult && diquestFilters.brandResult.length) {
				filters = diquestFilters.brandResult.map(v => {
					brandNameSplit = v.groupNm.split("||");
					brandName = brandNameSplit[1];

					originalCharCode = (window.Hangul.disassemble(brandName)[0]).toLowerCase().charCodeAt();
					charCode = originalCharCode;

					if(!(12593 <= charCode && 12622 >= charCode)) charCode += 12622;
					if((48 <= originalCharCode && 57 >= originalCharCode)) charCode += 10000;

					v.GROUP_ID = "b";
					v.DIS_TYPE = "search";
					v.TITLE = "브랜드";
					v.ID = brandNameSplit[0];
					v.NAME = brandName;
					v.ORIGINAL_NAME = v.groupNm;
					v.CONSONANT = (window.Hangul.disassemble(brandName)[0]).toLowerCase();
					v.CHAR_CODE = charCode;

					return v;
				});
			}

			return filters;
		},

		filterMakeBestBrandFilters(diquestFilters) {
			if(!diquestFilters) return [];

			let filters = [];
			let originalCharCode = null;
			let charCode = null;
			let brandNameSplit = null;
			let brandName = null;

			if(diquestFilters.bestBrandResult && diquestFilters.bestBrandResult.length) {
				filters = diquestFilters.bestBrandResult.map(v => {
					brandNameSplit = v.groupNm.split("||");
					brandName = brandNameSplit[1];

					originalCharCode = (window.Hangul.disassemble(brandName)[0]).toLowerCase().charCodeAt();
					charCode = originalCharCode;

					if(!(12593 <= charCode && 12622 >= charCode)) charCode += 12622;
					if((48 <= originalCharCode && 57 >= originalCharCode)) charCode += 10000;

					v.GROUP_ID = "b";
					v.DIS_TYPE = "search";
					v.TITLE = "브랜드";
					v.ID = brandNameSplit[0];
					v.NAME = brandName;
					v.ORIGINAL_NAME = v.groupNm;
					v.CONSONANT = "BEST";
					v.CHAR_CODE = 0;

					return v;
				});
			}

			return filters;
		},

		filterMakeCategoryFilters(diquestCategories) {
			if(!diquestCategories) return [];

			const categoriesOrder = this.filterGetCategoryKeys();
			let result = [];

			//
			let categories = null;
			let currentCategoryKey = null;
			let categoryChild = null;
			let splitCategory = null;
			let parentCategory = null;

			//
			let nameSplit = null;

			for(let i = 0, maxCnt = categoriesOrder.length; i < maxCnt; i++) {
				currentCategoryKey = categoriesOrder[i];

				if(!this.has.call(diquestCategories, currentCategoryKey)) continue;

				categories = diquestCategories[currentCategoryKey];

				if(categories && categories.length) {
					switch(currentCategoryKey) {
						case "lcateResult":
							categories = categories.map(v => {
								nameSplit = v.groupNm.split("||");

								return {
									TITLE: "카테고리",
									DIS_TYPE: this.filterCategory.type,
									GROUP_ID: this.filterCategory.key,
									PARENT: null,
									ORIGINAL_NAME: v.groupNm,
									ID: nameSplit[0],
									NAME: nameSplit[1],
								}
							});
							result = result.concat(categories);
						break;
						case "mcateResult":
						case "scateResult":
						case "dcateResult":
							for(let j = 0, maxCntJ = categories.length; j < maxCntJ; j++) {
								categoryChild = categories[j];

								if(!categoryChild || !categoryChild.groupNm) continue;

								splitCategory = categoryChild.groupNm.split(/\s*(@@[^@@]+$)/);
								nameSplit = splitCategory[1].replace("@@", "").split("||");
								parentCategory = result.find(v => v.ORIGINAL_NAME === splitCategory[0]);

								if(parentCategory) {
									result.push({
										TITLE: "카테고리",
										DIS_TYPE: this.filterCategory.type,
										GROUP_ID: this.filterCategory.key,
										PARENT: parentCategory.ORIGINAL_NAME,
										ORIGINAL_NAME: categoryChild.groupNm,
										ID: nameSplit[0],
										NAME: nameSplit[1],
									});
								}
							}
						break;
					}
				}
			}

			return result;
		},
		
		filterMakeFilters(filterRows, brandRows, brandBestRows) {
			let filters = [];
			let row = null;

			if(filterRows && filterRows.length) {
				for(let i = 0, maxCnt = filterRows.length; i < maxCnt; i++) {
					row = filterRows[i];

					if(!row.IL_ATTR_DETL || !row.IL_ATTR_DETL.length) continue;

					filters = filters.concat(row.IL_ATTR_DETL.map(v => {
						v.GROUP_ID = row.IL_ATTR_CD;
						v.DIS_TYPE = row.DIS_TYPE;
						v.TITLE = row.NAME;

						return v;
					}));
				}
			}



			if(brandRows && brandRows.length) {
				filters = filters.concat(brandRows.map(v => {
					const originalCharCode = (window.Hangul.disassemble(v.BRAND_NAME)[0]).toLowerCase().charCodeAt();
					let charCode = originalCharCode;
	
					if(!(12593 <= charCode && 12622 >= charCode)) charCode += 12622;
					if((48 <= originalCharCode && 57 >= originalCharCode)) charCode += 10000;
	
					v.ID = v.IL_BRAND_ID;
					v.GROUP_ID = this.filterBrand.key;
					v.DIS_TYPE = "search";
					v.TITLE = "브랜드";
					v.NAME = v.BRAND_NAME;
					// BEST 랑 분기
					v.CONSONANT = (window.Hangul.disassemble(v.BRAND_NAME)[0]).toLowerCase();
					v.CHAR_CODE = charCode;
	
					return v;
				}));
			}

			if(brandBestRows && brandBestRows.length) {
				filters = filters.concat(brandBestRows.map(v => {
					v.ID = v.IL_BRAND_ID;
					v.GROUP_ID = this.filterBrand.key;
					v.DIS_TYPE = "search";
					v.TITLE = "브랜드";
					v.NAME = v.BRAND_NAME;
					v.CONSONANT = "BEST";
					v.CHAR_CODE = 0;
	
					return v;
				}));
			}

			return filters;
		},
		
		filterResetCategory() {
			const filterSelectedCategories = this.filterSelectedCategories;

			filterSelectedCategories.first = null;
			filterSelectedCategories.second = null;
			filterSelectedCategories.third = null;

			this.isFilterCategoryActive1 = null;
			this.isFilterCategoryActive2 = null;
			this.isFilterCategoryActive3 = null;
		},

		filterModifyUrlSearch(methods, multiple, ids, values) {
			const queryString = this.getQueryString();
			let keys = null;
			let value = null;
			let parameters = null;
			let addedIndex = null;

			for(let i = 0, maxCnt = methods.length; i < maxCnt; i++) {
				if(!["add", "delete", "reset"].includes(methods[i])) {
					console.error("method not allowed...", methods[i]);

					return queryString;
				}

				value = values && values.length ? (values[i] || "").toString() : "";

				if("reset" == methods[i]) {
					keys = this.filtersApplied.map(v => v.ID);

					for(let j = 0, maxCnt = keys.length; j < maxCnt; j++) {
						queryString.delete(keys[j]);
					}
				}
				else if(multiple) {
					parameters = queryString.getAll(ids[i]);
					addedIndex = parameters.findIndex(v => decodeURIComponent(v) == value);

					if(queryString.has(ids[i])) {
						if(-1 != addedIndex) parameters.splice(addedIndex, 1);
						queryString.delete(ids[i]);

						for(let parameter of parameters) {
							queryString.append(ids[i], encodeURIComponent(parameter.replace(/ /g, "")));
						}
					}
					
					switch(methods[i]) {
						case "add":
							queryString.append(ids[i], encodeURIComponent(value.replace(/ /g, "")));
						break;
					}
				}
				else {
					queryString.delete(ids[i]);

					if("add" == methods[i]) queryString.append(ids[i], encodeURIComponent(value.replace(/ /g, "")));
				}
			}

			return queryString;
		},

		filterDestroyNoUiSlider() {
			const range = this.filterElements.range;

			if(range.noUiSlider) {
				range.noUiSlider.destroy();
				range.noUiSlider = null;
			}
		},

		// events
		filterToggleCategoryDetails(e, index) {
			const categories = this.$refs.categories;
			let category = null;

			if(!categories || !categories.length) {
				return;
			}

			this.isFilterCategoryActive1 = index;
			for(let i = 0, maxCnt = categories.length; i < maxCnt; i++) {
				category = categories[i];

				if(category.hasAttribute("open")) category.removeAttribute("open");
			}

			if(null !== index && undefined !== index) categories[index].setAttribute("open", "");
		},

		filterSelectedCategory(e, category, depth, index) {
			const filterSelectedCategories = this.filterSelectedCategories;
			const categoryDepth = Number.isInteger(depth) ? this.filterCategoryDepths[depth] : depth;
			const id = this.filterCategory.key;
			const name = `${category.ID}||${depth}||${category.NAME}`;
			const filtersApplied = this.filtersApplied.find(v => v.ID == id);
			const filtersAppliedIndex = this.filtersApplied.findIndex(v => v.ID == id);
			const currentFilterGroupKey = this.requests.filterGroups[this.filterGroupParamKey] || this.filterType;
			const categoryParent = this.filterGroups[currentFilterGroupKey][this.filterCategory.key].find(v => v.GROUP_ID === this.filterCategory.key && v.ORIGINAL_NAME === category.ORIGINAL_NAME);

			if(filtersApplied && filtersApplied.NAME == name) return;
			if(-1 !== filtersAppliedIndex) this.filtersApplied.splice(filtersAppliedIndex, 1);

			this.filtersApplied.push({
				TITLE: "카테고리",
				DIS_TYPE: this.filterCategory.type,
				GROUP_ID: this.filterCategory.key,
				PARENT: categoryParent.ORIGINAL_NAME,
				ORIGINAL_NAME: category.ORIGINAL_NAME,
				ID: id,
				NAME: name,
			});

			switch(categoryDepth) {
				case "first":
					if(filterSelectedCategories.first != name || filterSelectedCategories.third || filterSelectedCategories.second) {
						filterSelectedCategories.first = name;
						filterSelectedCategories.second = null;
						filterSelectedCategories.third = null;
					}
				break;
				case "second":
					if(filterSelectedCategories.second != name || (filterSelectedCategories.second == name && filterSelectedCategories.third)) {
						filterSelectedCategories.second = name;
						filterSelectedCategories.third = null;
					}
				break;
				case "third":
					if(filterSelectedCategories.third != name) filterSelectedCategories.third = name;
				break;
			}

			if(this.filterOptions.isHistoryURL) this.filterSetHistory(this.filterModifyUrlSearch(["add"], false, [id], [name]));
			if(this.filterOptions.isReload) {
				this.resetPagination(this.filterNextKey);
				this.reload(null, this.filterNextKey);
			}
		},

		filtersApply(rows) {
			const queryString = this.getQueryString();
			let filtersApplied = null;
			let category = null;
			let categoryParent = null;
			let categorySplit = null;
			let categorySplitParam = null;
			let categoryNameSplit = null;
			let priceRange = null;
			let minMax = null;

			for(let pair of queryString.entries()) {
				if(this.filterPrice.key == pair[0]) {
					priceRange = this.filterElements.range;
					minMax = decodeURIComponent(pair[1]).split("~");

					if(!priceRange.min && !priceRange.max && minMax && minMax.length) {
						priceRange.min = minMax[0];
						priceRange.max = minMax[1];
						filtersApplied = {
							ID: this.filterPrice.key,
							GROUP_ID: this.filterPrice.key,
							DIS_TYPE: this.filterPrice.type,
							NAME: `${minMax[0]} ~ ${minMax[1]}`,
							MIN: minMax[0],
							MAX: minMax[1],
							TITLE : "가격"
						};
						this.firstListLoaded = false;
					}
				}
				else if(this.filterCategory.key == pair[0] && pair[1]) {
					categorySplitParam = decodeURIComponent(pair[1]).split("||");

					if(categorySplitParam && 3 === categorySplitParam.length) {
						category = rows.find(v => v.GROUP_ID === this.filterCategory.key && v.ID == categorySplitParam[0]);

						if(category) {
							categorySplit = category.ORIGINAL_NAME.split(/\s*(@@[^@@]+$)/);
							categoryNameSplit = (categorySplit[1] || categorySplit[0]).replace("@@", "").split("||");
							categoryParent = rows.find(v => v.GROUP_ID === this.filterCategory.key && v.ORIGINAL_NAME === categorySplit[0]);


							filtersApplied = {
								TITLE: "카테고리",
								DIS_TYPE: this.filterCategory.type,
								GROUP_ID: this.filterCategory.key,
								ID: this.filterCategory.key,
								PARENT: categoryParent.ORIGINAL_NAME,
								NAME: decodeURIComponent(pair[1]),
								ORIGINAL_NAME: category.ORIGINAL_NAME,
							};

							this.filterInitSelectedCategory(rows, pair[1]);
						}
					}
				}
				else if(this.filterFree.key == pair[0]) { // 무료배송은 필터를 적용하지 않는다. (ORIGINAL_NAME 미존재)
					filtersApplied = {
						ID: "1-2001",
						GROUP_ID: "무료배송",
						NAME: "무료배송",
						MIN: "",
						MAX: ""
					};
					this.requests.goods.CONDITION_TYPE = 1;
				}
				else {
					filtersApplied = rows.find(v => v.ID == pair[0] && v.NAME.replace(/\s/g, "") == decodeURIComponent(pair[1]));
				}

				if(filtersApplied) this.filtersApplied.push(filtersApplied);
			}
		},

		filterFoldEvent(e) {
			const target = e.target;
			const targetBox = target.parentElement.parentElement;
			
			targetBox.classList.toggle("active");
		},

		filterSearchBrand(e, groupId) {
			const inputElement = this.$refs["brand-search-text" + groupId];
			const value = Array.isArray(inputElement) ? inputElement[0].value : inputElement.value;
		
			this.$set(this.filterSearch, groupId, value);
		},

		filterToggleMore(e, groupId) {
			const elements = this.$refs["filter-area" + groupId];

			if(!elements) return;
			if(elements.length) elements[0].classList.toggle("lnb__filter__area--open");
			else elements.classList.toggle("lnb__filter__area--open");
			
		},

		// events - noUiSlider
		filterModifyRange(e, type, range) {
			range.noUiSlider.set(["min" == type ? e.target.value : null, "max" == type ? e.target.value : null]);
		},

		/*
			events - filtersApplied filter
		* @param {String} isDirect  
		*/
		filterApplyPrice(isDirect, selId) {
			const range = isDirect ? this.filterElements.preset[0] : this.filterElements.range;
			const id = this.filterPrice.key;
			const name = `${numberWithCommas(range.MIN)}원 ~ ${numberWithCommas(range.MAX)}원`;
			const filtersApplied = this.filtersApplied.find(v => v.ID == id);

			if(filtersApplied && filtersApplied.NAME == name) return;
			if(filtersApplied) this.filtersApplied.splice(this.filtersApplied.findIndex(v => v.ID == id), 1);
			
			this.filtersApplied.push({
				ID: id,
				GROUP_ID: id,
				DIS_TYPE: this.filterPrice.type,
				NAME: name,
				MIN: range.MIN,
				MAX: range.MAX,
				TITLE : "가격",
				isDirect : isDirect,
				selId : selId
			});


			if(this.filterOptions.isHistoryURL) this.filterSetHistory(this.filterModifyUrlSearch(["add"], false, [id], [name]));
			if(this.filterOptions.isReload) {
				this.resetPagination(this.filterNextKey);
				this.reload(null, this.filterNextKey);

				//외부
				$(".filterNm[group_id=pr]").text(name);
				$(".filterNm[group_id=pr]").parents("button").addClass("btn_clicked");
				//내부
				$(".filter_name li[group_id=pr]").addClass("f_checked");
			}
		},

		filterDeleteAppliedItem(e, item) {
			const filtersApplied = this.filtersApplied;

			// 카테고리인 경우
			if(item.ID === this.filterCategory.key) this.filterResetCategory();

			//무료배송인 경우
			if(item.ID == '1-2001'){
				this.requests.goods.CONDITION_TYPE = '';
			}

			filtersApplied.splice(filtersApplied.findIndex(v => v.ID == item.ID), 1);

			this.filterResetRange();

			if(this.filterOptions.isHistoryURL) this.filterSetHistory(this.filterModifyUrlSearch(["delete"], true, [item.ID], [(item.NAME || "").replace(/ /g, "")]));
			if(this.filterOptions.isReload) {
				this.resetPagination(this.filterNextKey);
				this.reload(null, this.filterNextKey);
			}
		},

		filterResetRange() {
			const currentFilterGroupKey = this.requests.filterGroups[this.filterGroupParamKey] || this.filterType;
			const filters = this.filterGroups[currentFilterGroupKey];
			let price = null;

			if(filters) price = this.has.call(filters, this.filterPrice.key) ? filters[this.filterPrice.key] : null;
			if(price && price.length) this.filterInitRange(price[0].MIN, price[0].MAX);
		},

		filterResetAppliedItems(e, isReload) {
			const queryString = this.filterModifyUrlSearch(["reset"]);
			const appliedFilterCount = this.filtersApplied.length;

			this.filterResetCategory();
			this.filterResetRange();
			this.filterResetSearchBrand();
			this.filtersApplied = [];
			this.filterElements.range = []; 

			$(".filterCnt").text('');
			$("#filterKind button").removeClass("btn_clicked");
			$(".filterNm[group_id=pr]").text("가격"); 
			$(".is-price input[name=price]").prop("checked", false);
			$(".filter_name li").removeClass("f_checked");
			this.requests.goods.CONDITION_TYPE = ''; //무료배송

			if(this.filterOptions.isHistoryURL) this.filterSetHistory(queryString);

			// FIXME: watch 쪽에서 호출하느 ㄴ것이랑 어떻게 처리해야 될 것인치 고민해봐야 함...
			if(1 < appliedFilterCount) {
				this.resetPagination(this.filterNextKey);
				this.reload(null, this.filterNextKey);  
			}
		},

		filterResetSearchBrand(e, groupId) {
			let inputElement = null;
			let resultMatch = null;

			if(groupId) {
				inputElement = this.$refs["brand-search-text" + groupId];

				if(!inputElement) return;

				inputElement.value = "";
				this.filterSearch[groupId] = null;
			}
			else {
				for(let [key, value] of Object.entries(this.$refs)) {
					resultMatch = key.match(/^(brand-search-text)(.*)/);

					if(resultMatch && resultMatch.length) {
						inputElement = Array.isArray(value) ? value[0] : value;

						if(!inputElement) return;

						inputElement.value = "";

						this.filterSearch[resultMatch[2]] = null;
					}
				}
			}
		},

		// mobile only
		filterGetAppliedFiltersGroupCount(groupId) {
			const appliedFiltersGroup = this.filtersApplied.filter(v => v.GROUP_ID == groupId);

			return appliedFiltersGroup.length;
		},

		filterGetAppliedCategory() {
			const appliedCategory = this.filtersApplied.find(v => "ct" === v.ID);

			return appliedCategory ? appliedCategory : { ORIGINAL_NAME: null };
		},
		
		filterRsetSearchBrand(e, groupId) {
			const inputElement = this.$refs["brand-search-text" + groupId];

			inputElement.value = "";
			this.filterSearch[groupId] = null;
		},
	},
}

export default mixinControllerDFilters;