<template>
    <section class="fb__mypage-top">
		<div class="guide">
			<template v-if="false === fetches.orderStatistics">
				<div class="guide__membership">
					<span class="fb__skeleton"></span>
					<span class="fb__skeleton"></span>
				</div>
				<div class="guide__my-info">
					<div>
						<span class="fb__skeleton guide__my-info__order-count"></span>
						<span class="fb__skeleton"></span>
					</div>
					<div>
						<span class="fb__skeleton guide__my-info__mileage"></span>
						<span class="fb__skeleton"></span>
					</div>
					<div>
						<span class="fb__skeleton guide__my-info__available-coupon"></span>
						<span class="fb__skeleton"></span>
					</div>
				</div>
			</template>
			<template v-else-if="true === fetches.orderStatistics && mypageInfo">
				<div class="guide__membership">
					<span>고객님의 멤버십 등급은</span>
					<span><em class="guide__level">{{mypageInfo.GRP_NAME_M || "GOLD"}}</em>입니다.</span>
				</div>
				<div class="guide__my-info">
					<div>
						<span class="guide__my-info__order-count">{{orderStatistics.DI || 0}}</span>
						<span>주문내역</span>
					</div>
					<div>
						<span class="guide__my-info__mileage">{{mypageInfo.use_point || 0}}</span>
						<span>사용가능 포인트</span>
					</div>
					<div>
						<span class="guide__my-info__available-coupon">{{mypageInfo.my_cp_total || 0}}</span>
						<span>사용가능쿠폰</span>
					</div>
				</div>
			</template>
			<template v-else-if="'error' === fetches.orderStatistics">
				<div class="m-auto text-center">
					오류가 발생하였습니다.
					<div class="fb__mt--8">
						<button class="button__reload" clear @click="reload($event)">
							다시로드
						</button>
					</div>
				</div>
			</template>
		</div>
	</section>
</template>
<script>
    export default {
        name : "mypage-top-component",
		
		data() {
			return {
				fetches: {
					orderStatistics: false,
				},

				requests: {
					orderStatistics: {}
				},

				orderStatistics: {
					IC: 0,
					DI: 0,
					DC: 0,
					BF: 0,
				}
			}
		},

		props: {
            mypageInfo: {
                type: Object,
				default: () => {
					return {}
				},
			},
        },

		created() {
			this.requestOrderStatistics();
		},

        methods: {
			async requestOrderStatistics() {
				try {
					const parameters = this.requests.orderStatistics;
					const response = await this.$store.dispatch("network/request", {
						method: "post",
						url: "/biz/mypage/getMyShoppingHistoryCount",
						data: parameters
					});
					const rows = response.hiscount;

					for(let [key, value] of Object.entries(this.orderStatistics)) {
						const row = rows.find(v => key == v.CODE);

						if(row) this.orderStatistics[key] = row.COUNT || null;
					}

					this.fetches.orderStatistics = true;
				}
				catch(ex) {
					console.error("requestOrderStatistics exception...", ex);
					this.fetches.orderStatistics = "error";
				}
			},

			// events
			reload() {
				this.fetches.orderStatistics = false;
				this.requestOrderStatistics();
			},
		},
    }
</script>
<style lang="scss">
	.fb {
		&__mypage-top {
			background: #f7f7f7;
			border-top: 3px solid #000;

			&__skeleton {
				background: #fff;	
			}
			
			.guide {
				display: flex;
				align-items: center;
				height: 140px;
				padding: 0 40px 0 30px;

				&__membership {
					font-size: 22px;
					font-weight: 700;
					flex-basis: 485px;

					span {
						display: block;

						&.fb__skeleton {
							height: 20px;
							background: #fff;

							&:nth-of-type(1) {
								width: 205px;
							}

							&:nth-of-type(2) {
								width: 150px;
							}
						}
					}
				}

				&__level {
					font: {
						family: 'Archivo Black';
						weight: 300;
					}
				}

				&__my-info {
					display: flex;
					flex-basis: 465px;
					text-align: center;

					&__order-count, &__mileage, &__available-coupon {
						display: block;
						font-size: 34px;
						font-weight: 700;

						& + span {
							display: block;
							font-size: 14px;

							&:nth-of-type(n + 2) {
								margin-top: 12px;
							}
						}
					}

					div {
						margin-left: 85px;

						.fb__skeleton {
							width: 50px;
							height: 35px;
							background: #fff;

							&:nth-of-type(n + 2) {
								height: 15px;
							}
						}

						&:first-of-type {
							margin-left: 0;
						}
					}
				}
			}
		}
	}

</style>