const mixinPagesFilter = {
	data() {
		return {
			has: Object.prototype.hasOwnProperty,

			fetches: {
				categories: false,
			},
			requests: {
				categories: {
					IL_CTGRY_ID: null,
					MAIN_TYPE:mainType
				},
				subCategories: {
					IL_CTGRY_ID: null,
				},
			},

			//
			categoryTitle: null,
			categoryDepths: ["first", "second", "third"],
			categories: null,
			subCategories: {},
			selectedCategories: {},
		}
	},

	created() {
		const categoryDepths = this.categoryDepths;

		for(let i = 0, maxCnt = categoryDepths.length; i < maxCnt; i++) {
			this.$set(this.selectedCategories, categoryDepths[i], null); 
		}
	},

	methods: {
		async requestCategories() {
			const alert = this.alert;

			try {
				const parameters = this.requests.categories;
				const response = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/comn/il/dispCtgry/getUseDispCtgrys",
					data: parameters
				});
				const rows = response.rows;

				if(rows && rows.length) {
					this.categories = rows;
					this.categoryTitle = rows[0].PRNT_CTGRY_NAME;
					
					if(parameters.IL_CTGRY_ID == this.getLowestCategoryId()) this.fetches.categories = true;
					else this.requests.subCategories.IL_CTGRY_ID = this.getQueryString().get("s_c");
				}
				else {
					alert.message = "카테고리를 찾을 수 없습니다.<br/>잠시 후 메인페이지로 이동합니다.";
					alert.isCloseButton = false;
					alert.isOpen = true;

					setTimeout(() => {
						window.location.href = "/index";
					}, 3000);
				}
			}
			catch(ex) {
				console.error("requestCategories exception...", ex);

				this.fetches.categories = "error";
			}
		},

		async requestSubCategories() {
			const alert = this.alert;
			
			try {
				const parameters = this.requests.subCategories;
				const response = await this.$store.dispatch("network/request", {
					method: "post",
					url: "/comn/il/dispCtgry/getUseDispCtgrys",
					data: parameters
				});
				const rows = response.rows;
				const parentCategory = this.categories.find(v => v.IL_CTGRY_ID == this.getSecondCategoryId());

				if(!parentCategory) {
					alert.message = "카테고리를 찾을 수 없습니다.<br/>잠시 후 메인페이지로 이동합니다.";
					alert.isCloseButton = false;
					alert.isOpen = true;

					setTimeout(() => {
						window.location.href = "/index";
					}, 3000);

					return;
				}

				this.$set(parentCategory, "rows", rows && rows.length ? rows : []);

				if(this.fetches.categories) this.reload(null, "filterGroups");
				else this.fetches.categories = true;
			}
			catch(ex) {
				console.error("requestSubCategories exception...", ex);
			}
		},

		// default - get
		getFirstCategoryId() {
			const queryString = this.getQueryString();

			return queryString.get("f_c");
		},

		getSecondCategoryId() {
			const queryString = this.getQueryString();

			return queryString.get("s_c");
		},

		getThirdCategoryId() {
			const queryString = this.getQueryString();

			return queryString.get("t_c");
		},

		getLowestCategoryId() {
			const queryString = this.getQueryString();

			return queryString.get("t_c") || queryString.get("s_c") || queryString.get("f_c");
		},

		getLowestCategoryType() {
			const queryString = this.getQueryString();

			return (queryString.get("t_c") ? "t_c" : null) || (queryString.get("s_c") ? "s_c" : null) || (queryString.get("f_c") ? "f_c" : null);
		},

		// events
		resetSelectedCategoryies() {
			const categoryDepths = this.categoryDepths;
			let categoryDepth = null;

			for(let i = 0, maxCnt = categoryDepths.length; i < maxCnt; i++) {
				categoryDepth = categoryDepths[i];

				this.selectedCategories[categoryDepth] = null;
			}
		},

		selectedCategory(e, category, depth) {
			const categoryDepths = this.categoryDepths;
			const selectedCategories = this.selectedCategories;
			let categoryId = null;
			let categoryDepth = Number.isInteger(depth) ? categoryDepths[depth] : depth;

			switch(categoryDepth) {
				case "first":
					if(selectedCategories.third || selectedCategories.second) {
						selectedCategories.second = null;
						selectedCategories.third = null;
					}
				break;
				case "second":
					categoryId = category.IL_CTGRY_ID;

					if(selectedCategories.second != categoryId || (selectedCategories.second == categoryId && selectedCategories.third)) {
						selectedCategories.second = categoryId;
						selectedCategories.third = null;
					}
				break;
				case "third":
					categoryId = category.IL_CTGRY_ID;

					if(selectedCategories.third != categoryId) selectedCategories.third = categoryId;
				break;
			}
		},

		selectedSubCategory(e, subCategory) {
			const goodsParameters = this.requests.goods;
			const selectedCategories = this.selectedCategories;
			const subCategoryId = subCategory.IL_CTGRY_ID;

			if(selectedCategories.third != subCategoryId) {
				selectedCategories.third = subCategoryId;
				goodsParameters.IL_CTGRY_ID = subCategoryId;
			}
		},
	},
}

export default mixinPagesFilter;