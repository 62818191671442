<template>
    <section class="fixed top-0 left-0 w-full h-full flex flex-no-wrap flex-col items-center z-50 1234" :class="{'noDim' : !isBackground}" @click.self="isBackgroundClose ? close() : null" style="overflow-y: initial !important">
		<div class="confirm__wrapper m-auto bg-white popup__alert new_mo " :style="`${width ? 'width: ' + width + ';' : ''}${height ? 'height: ' + height + ';' : ''}`">
			<div class="layer__header">
                    <a href="javascript:;" class="layer__close" @click.stop.prevent="closePopupOnly">닫기</a>
			</div>
			<div class="confirm__content text-center" v-html="message || ''"></div>
			<footer class="text-right">
				<button type="button" @click="close($event, false)">{{cancel || "아니오"}}</button>
				<button type="button" @click="close($event, true)">{{ok || "예"}}</button>
			</footer>
		</div>
	</section>
</template>
<script>
    export default {
        name : "confirm-component",

		props: {
			isBackgroundClose: {
				type: Boolean,
				default: false
			},

			isBackground: {
				type: Boolean,
				default: true
			},

			width: {
				type: String | Number,
				default: null
			},

			height: {
				type: String | Number,
				default: null
			},

			message: {
				type: String,
				default: ""
			},

			ok: {
				type: String,
				validator: prop => typeof prop === 'string' || prop === null,
				default: "예"
			},

			cancel: {
				type: String,
				default: "아니오"
			}
		},
		
		mounted() {
			document.body.style.width = document.body.scrollWidth + "px";
			document.body.classList.add("scroll-lock");
		},

		beforeDestroy() {
			document.body.style.width = null;
			document.body.classList.remove("scroll-lock");
		},
		
        methods: {
			close(e, value) {
				this.$emit("close-confirm", {
					e,
					value
				});
			},

			testscroll(e) {
				console.log('e: ', e);
			},

			closePopupOnly() {
				this.$emit("close");
			},
		},
    }
</script>
<style scoped lang="scss">
	section {
		$max-width: 80vw;
		$min-width: 40vw;
		background: rgba(0, 0, 0, .5);

		&.noDim {
			background: none;
		}

		.confirm__wrapper {
			max-width: $max-width;
			min-width: $min-width;
			box-shadow: 0 0.266vw 0.533vw -1px rgba(0, 0, 0, 0.2), 0 0.533vw 0.666vw rgba(0, 0, 0, 0.14), 0 1px 1.333vw rgba(0, 0, 0, 0.12);
			width:89.333vw;
			position: fixed;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		.confirm__content {
			padding-top: 2.667vw;
			line-height: 5.066vw;
			font-size: 4vw;
		}

		footer {
			margin-top: 10.667vw;
			text-align: center;
			font-size: 0;

			button {
				width: 50%;
				height: 16vw;
				padding: 0;
				line-height: 5.066vw;
				font-size: 4vw;
				color: #fff;
				background-color:#000;
				border-top: 0.133vw solid #000;
				box-sizing:inherit;

				&:first-child {
					color:#000;
					background-color: #fff;
				}
			}
		}
	}
</style>