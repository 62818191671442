<template>
  <section class="fb__sns" aria-label="SNS">
    <div class="fb__sns__sec">
      <ul class="sns">
        <li class="sns__list">
          <button
            type="button"
            class="sns-btn kakao"
            id="kakao-link-btn"
            @click="kakao()"
          >
            <span class="blind">카카오톡</span>
          </button>
        </li>
        <li class="sns__list">
          <button type="button" class="sns-btn facebook" @click="fb()">
            <span class="blind">페이스북</span>
          </button>
        </li>
        <li class="sns__list">
          <button type="button" class="sns-btn url" @click="urlCopy()">
            <span class="blind">URL복사</span>
          </button>
        </li>
        <li class="sns__list" v-show="true === shareLink">
          <button type="button" class="sns-btn more" @click="shareBtn()">
            <span class="blind">더보기</span>
          </button>
        </li>
      </ul>
      <button type="button" class="btn__close" @click="close">
        <span class="blind">닫기</span>
      </button>
    </div>
    <input type="text" id="Url" value="" style="opacity: 0;" readonly />

    <alert-component
      :message="alert.message"
      aria-modal="알림"
      @close-alert="alert.isOpen = false"
      v-if="alert.isOpen"
    ></alert-component>
  </section>
</template>
<script>
import alertComponent from "./alert-component.vue";

export default {
  name: "sns-component",
  components: { alertComponent },
  props: {},

  data() {
    return {
      shareLink: false,
      alert: {
        width: null,
        height: null,
        isBackgroundClose: false,
        isOpen: false,
        message: null,
      },
      url: {
        u: location.href,
        t: document.title,
      },
    };
  },

  mounted() {
    document.body.style.marginTop = -window.pageYOffset + "px";
    document.body.style.position = "fixed";

    if (navigator.share) {
      this.shareLink = true;
    } else {
      this.shareLink = false;
    }

    // FIXME: STORE에 넣어서 해 주세요
    if (Kakao && !window.kakaoInit) {
      Kakao.init("3a0fae3e22d3501229f2bc0684ce4a00"); // 실제 사용중인 까사미아 자바스크립트 키 open 시 사용
      window.kakaoInit = true;
    }
  },

  beforeDestroy() {
    document.body.style.position = "";
    window.scrollTo(0, -document.body.style.marginTop.replace("px", ""));
    document.body.style.marginTop = "";
  },

  methods: {
    // events
    close(e) {
      this.$emit("close-sns", {
        e,
      });
    },

    fb() {
      let link = document.location.href;
      let url =
        "http://www.facebook.com/sharer.php?u=" +
        encodeURIComponent(this.url.u) +
        "&t=" +
        encodeURIComponent(this.url.t);
      window.open(url);
    },

    kakao() {
      if (Kakao) {
        const snsInfoData = snsInfo.DETL.SNS_INFO.KAKAOTALK;
        const imgUrl = snsInfo.IMAGE_SERVER_HOST + snsInfoData.M_IMG;
        const _description = snsInfoData.CONTS ? snsInfoData.CONTS : "";
        const _keywords = snsInfoData.FIND_KYWRD ? snsInfoData.FIND_KYWRD : "";
        let kakaoDesc =
          window.location.pathname == "/event/goodsEventDetail"
            ? _keywords
            : _description;

        Kakao.Link.sendDefault({
          objectType: "feed",
          content: {
            title: `${snsInfoData.TITLE}`,
            description: kakaoDesc,
            imageUrl: imgUrl,
            link: {
              mobileWebUrl: document.location.href,
              webUrl: document.location.href,
            },
          },
          social: {
            likeCount: 0,
            commentCount: 0,
            sharedCount: 0,
          },
          buttons: [
            {
              title: "자세히보기",
              link: {
                mobileWebUrl: document.location.href,
                webUrl: document.location.href,
                iosExecParams: document.location.href,
                androidExecParams: document.location.href,
              },
            },
          ],
        });
      }
    },

    urlCopy() {
      let link = document.location.href;
      let copyText = document.getElementById("Url");
      copyText.value = link;
      this.alert.message = "URL 복사가 되었습니다.";
      this.alert.isOpen = true;
      copyText.setAttribute("type", "text");
      copyText.select();
      document.execCommand("Copy");
    },

    shareBtn() {
      // 디바이스 공유하기 https인 경우에만 동작을 함.
      const link = document.location.href;

      if (navigator.share) {
        navigator
          .share({
            url: link,
          })
          .then(() => {
            //console.log('Thanks for sharing!');
          })
          .catch(console.error);
      } else {
        // fall back
      }
    },
  },
};
</script>
<style scoped lang="scss">
.fb__sns {
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  &__sec {
    position: absolute;
    top: 50%;
    left: 50%;
    padding-top: 7.333vw;
    transform: translate3d(-50%, -50%, 0);
  }

  .sns {
    width: 76vw;
    font-size: 0;
    text-align: center;

    &__list {
      display: inline-block;
      width: 16vw;
      height: 16vw;
      margin-left: 4vw;
      vertical-align: top;
      font-size: 0;

      &:first-child {
        margin-left: 0;
      }
    }

    &-btn {
      width: 100%;
      height: 100%;
      $snsName: "kakao" "#ffe812" "0px", "facebook" "#2b5ec5" "-16vw",
        "url" "#fff" "-32vw", "more" "#4f4f4f" "-48vw";
      border-radius: 50%;

      @each $snsNames, $bgColor, $pos in $snsName {
        &.#{$snsNames} {
          background: #{$bgColor}
            url(/mobile/assets/images/common/ico_comm_sns-total.png)
            no-repeat
            #{$pos}
            0 /
            64vw
            16vw;
        }
      }
    }
  }

  .btn__close {
    position: absolute;
    top: 0;
    right: -6.667vw;
    width: 4.667vw;
    height: 4.667vw;
    background: url(/mobile/assets/images/common/ico_comm_layer-close.png)
      no-repeat 0 0 / 100% auto;
  }
}
</style>
