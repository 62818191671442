<template>
	<section :class="classes">
		<div class="inp__select__box" :class="isOpen ? 'open' : ''">
			<button type="button" class="inp__select__btn" :disabled="disabled" @click.self="selectboxOpen()">
				<slot></slot>
			</button>
			<div class="inp__select__options">
				<slot name="options"></slot>
			</div>
		</div>
	</section>
</template>
<script>
	import eventBus from "../mixins/event-bus";
    export default {
		name : "select-custom-component",
		mixins: [eventBus],
		props: {
			classes: {
				type: String,
				default: null
			},

			disabled: {
				type: Boolean,
				default: false
			}
		},

		data() {
			return {
				isOpen: false
			}
		},

		watch: {
			isOpen: {
				handler(newValue, oldValue) {
					
				},
			}
		},

		created() {
			this.$parent.$on("select-open", (isOpen) => {
				this.isOpen = isOpen;
			});

			this.$on("select-open", (isOpen) => {
				this.isOpen = isOpen;
			});
		},

		methods: {
			selectboxOpen() {
				const selectBox = this.$el.querySelector(".inp__select__box");
				this.$nextTick(() => {
					if (!selectBox.classList.contains("open")) {
						this.isOpen = true;
						selectBox.classList.add("open");
					} else {
						this.isOpen = false;
						selectBox.classList.remove("open");
					}
				});
			},
		}
    }
</script>
<style scoped lang="scss">
	.inp {
		&__select {
			position:relative;
			display: block;
			width: 100%;
			
			&__btn {
				position: relative;
				width: 100%;
				height: 11.733vw;
				padding: 0 9.59999vw 0 3.33333vw;
				border: 1px solid #EBEBEB;
				color: #000;
				font-size: 4vw;
				line-height: 7.33333vw;
				background: #fff url("/mobile/assets/images/common/ico_select.png") no-repeat calc(100% - 5.0667vw) center;
				background-size: 2.93333vw auto;
			}
			&__box {
				.inp__select__btn {
					text-align: left;
				}
				position: relative;
				&.open {
					.inp__select__btn {
						border: 1px solid #000;
					}
					.inp__select__options {
						display:block;
					}
				}
			}
			&__options {
				display: none;
				position: absolute;
				left:0;
				top: calc(11.733vw - 1px);
				width:100%;
				max-height:40vw;
				padding: 1.06vw 4.8vw 1.06vw 4vw;
				border: 1px solid #000;
				border-top:1px solid #EBEBEB;
				overflow-x:hidden;
				overflow-y: auto;
				background-color: #fff;
				z-index: 10;

				&::-webkit-scrollbar {
					width: 3px;  /* 스크롤바의 너비 */
					margin-right: 10px;
				}
				&::-webkit-scrollbar-thumb {
					height: 30%; /* 스크롤바의 길이 */
					background: rgba(0,0,0,0.2); /* 스크롤바의 색상 */
					border-radius: 20px;
					visibility: visible;
				}
				
				label {
					display: block;
					width: 100%;
					padding: 2vw 0;
					text-align: left;
					color: #787878;
					font-size: 3.466vw;
					line-height:1.5;
				}
				input[type=radio]:checked + span {
					color:#000;
				}
				input[type=radio]:disabled + span {
					color:#ccc;
				}
			}
		}

	}
</style>